<template>
  <b-card class="mb-4" no-header>
    <!-- <template #header>
      <b-row>
        <b-col cols="10">
          <h5 class="mb-0">{{$route.name}}</h5>
        </b-col>
        <b-col cols="2" class="text-right">
          <b-button-group class="add-button-group">
            <b-btn variant="primary" href="/dpts/create">Tambah Data</b-btn>
          </b-button-group>
        </b-col>
      </b-row>
    </template> -->
    <b-row>
      <b-col cols="12">
        <div class="d-flex mb-3">
          <!-- <b-button-group class="add-button-group">
            <b-btn variant="primary" to="/dpts/create">Tambah Data</b-btn>
          </b-button-group> -->
          <b-button-group class="add-button-group ml-auto">
            <b-btn variant="success" type="button" @click.prevent="doExport"><font-awesome-icon icon="file-excel"/> Ekspor</b-btn>
          </b-button-group>
        </div>
      </b-col>
      <b-col cols="12" class="mb-4">
        <b-form @submit.prevent="doFilter">
          <b-row class="filter-row">
            <b-col cols="5" md="5">
              <form-group-input ref="filter-name" type="text" placeholder="" :need-label="true" label="nama" v-model="filter.name" id="filter-name" />
            </b-col>
            <b-col cols="3" md="3">
              <form-group-input ref="filter-nik" type="text" placeholder="" :need-label="true" label="NIK" v-model="filter.nik" id="filter-nik" />
            </b-col>
            <b-col cols="3" md="3">
              <form-group-input ref="filter-nkk" type="text" placeholder="" :need-label="true" label="NKK" v-model="filter.nkk" id="filter-nkk" />
            </b-col>
            <b-col cols="1" md="1">
              <form-group-input ref="filter-tps" type="number" min="1" placeholder="" :need-label="true" label="tps" v-model="filter.tps" id="filter-tps" />
            </b-col>
          </b-row>
          <b-row class="filter-row">
            <b-col cols="6" md="3">
              <form-group-input ref="filter-city_id" type="enum" :options="list.city" placeholder="" :need-label="true" label="Kota/Kabupaten" v-model="filter.city_id" id="filter-city_id" />
            </b-col>
            <b-col cols="6" md="3">
              <form-group-input ref="filter-district_id" type="enum" :options="list.district" placeholder="" :need-label="true" label="Kecamatan" v-model="filter.district_id" id="filter-district_id" />
            </b-col>
            <b-col cols="6" md="3">
              <form-group-input ref="filter-village_id" type="enum" :options="list.village" placeholder="" :need-label="true" label="Kelurahan" v-model="filter.village_id" id="filter-village_id" />
            </b-col>
            <b-col cols="6" md="2">
              <label class="form-label">&nbsp;</label><br>
              <b-btn type="submit" class="btn-in-form" variant="primary">
                <font-awesome-icon icon="search"/><span>Cari</span>
              </b-btn>
              <b-btn type="button" @click.prevent="clearForm" variant="secondary" class="btn-in-form ml-2">
                <font-awesome-icon icon="broom"/><span>Clear</span>
              </b-btn>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>
    <paginated-table ref="dataTable" :no-actions="true" :data-url="apiUrl.dpt" :data-columns="columns" :dataParams="dataParams" :defaultDataParams="this.filter">
      <template slot="content_city_id" slot-scope="defaultSlotScope">
        <span style="text-transform: capitalize;">{{defaultSlotScope.colData.city_id !== null ? defaultSlotScope.colData.city.name : ''}}</span>
      </template>
      <template slot="content_district_id" slot-scope="defaultSlotScope">
        <span style="text-transform: capitalize;">{{defaultSlotScope.colData.district_id !== null ? defaultSlotScope.colData.district.name : ''}}</span>
      </template>
      <template slot="content_village_id" slot-scope="defaultSlotScope">
        <span style="text-transform: capitalize;">{{defaultSlotScope.colData.village_id !== null ? defaultSlotScope.colData.village.name : ''}}</span>
      </template>
      <template slot="content_tps" slot-scope="defaultSlotScope">
        <span style="text-transform: capitalize;">{{defaultSlotScope.colData.tps}}</span>
      </template>
      <template slot="content_gender" slot-scope="defaultSlotScope">
        <span style="text-transform: capitalize;">{{defaultSlotScope.colData.gender == 'laki-laki' ? 'Laki-Laki' : 'Perempuan' }}</span>
      </template>
    </paginated-table>
  </b-card>
</template>

<script>
import { objectToOptions } from '@/_helpers'
// import { objectToOptions, getYears, formatModelValue } from '@/_helpers'
import paginatedTable from '@/components/PaginatedTable.vue'
import formGroupInput from '@/components/FormGroupInput.vue'
import Dpt from '@/models/Dpt.js'
import City from '@/models/City.js'
import District from '@/models/District.js'
import Village from '@/models/Village.js'

const dptModel = new Dpt()
const cityModel = new City()
const districtModel = new District()
const villageModel = new Village()

export default {
  components: {
    formGroupInput,
    paginatedTable
  },
  data () {
    return {
      isLoading: false,
      totalPromiseAreaCount: 0,
      apiUrl: {
        dpt: dptModel.endpoint
      },
      columns: [
        {
          prop: 'nik',
          label: 'NIK',
          sortable: true,
          resizable: true,
          minWidth: 160
        },
        {
          prop: 'nkk',
          label: 'NKK',
          sortable: true,
          resizable: true,
          minWidth: 160
        },
        {
          prop: 'name',
          label: 'Nama',
          sortable: true,
          resizable: true,
          minWidth: 250
        },
        // {
        //   prop: 'phone_number',
        //   label: 'Kontak',
        //   resizable: true,
        //   sortable: true,
        //   minWidth: 120
        // },
        // {
        //   align: 'center',
        //   prop: 'dapil',
        //   label: 'DAPIL',
        //   resizable: true,
        //   sortable: true,
        //   minWidth: 80
        // },
        {
          prop: 'city_id',
          label: 'Kabupaten',
          resizable: true,
          sortable: true,
          minWidth: 210
        },
        {
          prop: 'district_id',
          label: 'Kecamatan',
          resizable: true,
          sortable: true,
          minWidth: 210
        },
        {
          prop: 'village_id',
          label: 'Kelurahan',
          resizable: true,
          sortable: true,
          minWidth: 210
        },
        {
          align: 'center',
          prop: 'tps',
          label: 'TPS',
          resizable: true,
          sortable: true,
          minWidth: 80
        },
        {
          prop: 'hometown',
          label: 'Tempat Lahir',
          resizable: true,
          sortable: true,
          minWidth: 210
        },
        {
          align: 'center',
          prop: 'age',
          label: 'Umur',
          resizable: true,
          sortable: true,
          minWidth: 70
        },
        {
          align: 'center',
          prop: 'marriage_status',
          label: 'Status',
          resizable: true,
          sortable: true,
          minWidth: 80
        },
        {
          align: 'center',
          prop: 'gender',
          label: 'Jekel',
          resizable: true,
          sortable: true,
          minWidth: 120
        },
        {
          prop: 'address',
          label: 'Alamat',
          resizable: true,
          sortable: true,
          minWidth: 200
        },
      ],
      list: {
        city: [],
        district: [],
        village: [],
      },
      filter: {
        nik: typeof this.$route.query.nik !== 'undefined' ? this.$route.query.nik : null,
        nkk: typeof this.$route.query.nkk !== 'undefined' ? this.$route.query.nkk : null,
        name: typeof this.$route.query.name !== 'undefined' ? this.$route.query.name : null,
        dapil: typeof this.$route.query.dapil !== 'undefined' ? this.$route.query.dapil : null,
        tps: typeof this.$route.query.tps !== 'undefined' ? this.$route.query.tps : null,
        city_id: typeof this.$route.query.city_id !== 'undefined' ? this.$route.query.city_id : null,
        district_id: typeof this.$route.query.district_id !== 'undefined' ? this.$route.query.district_id : null,
        village_id: typeof this.$route.query.village_id !== 'undefined' ? this.$route.query.village_id : null,
        with_area_detail: 1,
        status_dpt: 1,
      },
      dataParams: ['name', 'dapil', 'tps', 'city_id', 'district_id', 'village_id', 'with_area_detail', 'nik', 'nkk', 'status_dpt']
    }
  },
  beforeMount () {
    this.$router.push({query: {...this.$route.query}}).catch(err => {})
  },
  async mounted () {
    if (typeof this.$route.query.city_id !== 'undefined') {
      this.totalPromiseAreaCount++;
      this.list.district = objectToOptions(await this.getDistricts(this.$route.query.city_id))
    }
    if (typeof this.$route.query.district_id !== 'undefined') {
      this.totalPromiseAreaCount++;
      this.list.village = objectToOptions(await this.getVillages(this.$route.query.district_id)) 
    }
    
    this.getCities()
  },
  watch: {
    'filter.city_id': async function (val, oldVal) {
      if (val) {
        this.list.district = objectToOptions(await this.getDistricts(val))
        if (val !== oldVal) {
          this.totalPromiseAreaCount++;
          this.filter.district_id = null
        }
      } else {
        this.filter.district_id = null
      }
    },
    'filter.district_id': async function (val, oldVal) {
      if (val) {
        this.list.village = objectToOptions(await this.getVillages(val))
        if (val !== oldVal) {
        this.totalPromiseAreaCount++;
          this.filter.village_id = null
        }
      } else {
        this.filter.village_id = null
      }
    },
    'totalPromiseAreaCount': async function (val) {
      if (val >= 4) {
        this.initEdit = false;
      }
    },
  },
  methods: {
    hundredFormat (val) {
      if (val < 10) {
        return '00' + val;
      } else if (val < 100) {
        return '0' + val;
      }
      return val;
    },
    getCities () {
      cityModel.list({
        'registered_only': 1,
        // 'name': 'labuhan',
        'province_id': '950b9d3a-c996-4207-97e7-5a2858981979',
        'limit': 10000
      }).then(resp => {
        let data = []
        // data[null] = 'Tidak Ada'
        resp.data.forEach(d => {
          data[d.id] = d.name
        })
        this.list.city = objectToOptions(data)
      })
    },
    async getDistricts (cityId) {
      return new Promise(resolve => {
        districtModel.list({
          'city_id': cityId
        }).then(resp => {
          let data = []
          // data[null] = 'Tidak Ada'
          resp.data.forEach(d => {
            data[d.id] = d.name
          })
          resolve(data);
          // this.dptModel.district_id.options = data
        })
      })
    },
    async getVillages (districtId) {
      return new Promise(resolve => {
        villageModel.list({
          'district_id': districtId,
        }).then(resp => {
          let data = []
          // data[null] = 'Tidak Ada'
          resp.data.forEach(d => {
            data[d.id] = d.name
          })
          resolve(data);
          // this.dptModel.village_id.options = data
        })
      })
    },
    clearForm () {
      this.$nextTick(() => {
        this.filter = {
          status_dpt: 1,
          with_area_detail: 1,
          nik: null,
          nkk: null,
          name: null,
          dapil: null,
          tps: null,
          city_id: null,
          district_id: null,
          village_id: null,
        }
        setTimeout(() => {
          this.doFilter()
        }, 100)
      })
    },
    doFilter () {
      this.$refs.dataTable.doFilter(this.filter)
    },
    doExport () {
      if (this.isLoading) {
        return false;
      }
      this.isLoading = true;
      const filterArray = [];
      Object.keys(this.filter).forEach(key => {
        if (this.filter[key]) {
          filterArray.push(key + "=" + this.filter[key]);
        }
      });
      filterArray.push('csv=1');

      this.$http.get(dptModel.getEndpoint() + "?" + filterArray.join("&")).then(response => {
        const a = document.createElement("a");

        const blob = new Blob([response.data], {type: "octet/stream"});
        a.href = window.URL.createObjectURL(blob);

        a.download = "data_dpt.csv";
        a.click();
        this.isLoading = false;
      }).catch(error => {
        this.$store.dispatch('notification/error', error)
        this.isLoading = false;
      })
    }
  }
}
</script>