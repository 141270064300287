<template>
  <div>
    <back-button default-to="/staff-calon"/>
    <b-card no-header>
      <b-row>
        <b-col cols="12" md="6">
          <h5>Informasi Pribadi</h5>
          <template v-for="(m, index) in filterModel(['name', 'nik', 'gender', 'hometown', 'dob', 'city_id', 'district_id', 'village_id', 'address', 'phone_number'])">
            <div :key="'pribadi_' + index">
              <detail-group-input :ref="index" :type="m.type" :label="typeof m.label === 'string' ? m.label : null" :need-label="true" v-model="m.value" :id="index" />
            </div>            
          </template>
        </b-col>
        <b-col cols="12" md="6">
          <h5>Informasi Tugas</h5>
          <template v-for="(m, index) in filterModel(['role', 'username', 'work_area_city_id', 'work_area_district_id', 'work_area_village_id', 'tps'])">
            <div :key="'tugas_' + index">
              <detail-group-input :ref="index" :type="m.type" :label="typeof m.label === 'string' ? m.label : null" :need-label="true" v-model="m.value" :id="index" />
            </div>
          </template>
        </b-col>
      </b-row>
      <div>
        <b-button :to="'/staff-calon/' + $route.params.id + '/edit'" variant="primary" class="mt-0">Ubah</b-button>
        <b-button :to="'/staff-calon/'" variant="secondary" class="ml-3 float-right">Kembali</b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import { setModelValue } from '@/_helpers'
import detailGroupInput from '@/components/DetailGroupInput.vue'
import StaffCalon from '@/models/StaffCalon.js'

const staffCalonModel = new StaffCalon()

export default {
  components: {
    detailGroupInput
  },
  watch: {
    '$route.params.id': function (val) {
      if (val !== undefined) {
        this.getDetail()
      }
    }
  },
  data () {
    return {
      isLoading: false,
      model: staffCalonModel.init()
    }
  },
  mounted () {
    this.model.password.type = 'hidden'
    this.getDetail()
    // alert(window.history.length)
  },
  methods: {
    filterModel (search) {
      const filtered = Object.entries(this.model).filter(mdl => {
        return search.includes(mdl[0])
      });
      let result = {}
      filtered.forEach(element => {
        result[element[0]] = element[1];
      });
      return result;
    },
    getDetail () {
      this.isLoading = true
      staffCalonModel.find(this.$route.params.id, {
        detail: 1
      }).then(resp => {
        this.isLoading = false
        let response = {}
        // response = resp
        Object.keys(resp).forEach(key => {
          if (!['user_detail', 'user_work_area'].includes(key)) {
            response[key] = resp[key]
          }
        });
        Object.keys(resp.user_detail).forEach(key => {
          if (key === 'gender') {
            response[key] = resp.user_detail[key] === 'laki-laki' ? 'Laki-Laki' : 'Perempuan'
          } else if (key === 'city_id') {
            response[key] = resp.user_detail['city'].name
          } else if (key === 'district_id') {
            response[key] = resp.user_detail['district'].name
          } else if (key === 'village_id') {
            response[key] = resp.user_detail['village'].name
          } else if (key === 'province_id') {
            response[key] = resp.user_detail['province'].name
          } else if (!['user_id', 'village', 'city', 'district', 'province'].includes(key)) {
            response[key] = resp.user_detail[key]
          }
        });
        // Object.keys(resp.user_work_area).forEach(key => {
        //   if (key === 'work_area_city_id') {
        //     response[key] = resp.user_work_area['city'].name
        //   } /* else if (key === 'dapil_id') {
        //     response[key] = resp.user_work_area['dapil'] ? resp.user_work_area['dapil'].name :'-'
        //   } */ else if (key === 'work_area_district_id') {
        //     response[key] = resp.user_work_area['district'].name
        //   } else if (key === 'work_area_village_id') {
        //     response[key] = resp.user_work_area['village'] ? resp.user_work_area['village'].name : '-'
        //   } else if (key === 'work_area_province_id') {
        //     response[key] = resp.user_work_area['province'].name
        //   } else if (!['user_id', 'village', 'city', 'district', 'province'].includes(key)) {
        //     response[key] = resp.user_work_area[key]
        //   }
        // });
        response['phone_number'] = response['phone_number'].replace('+62', '0');
        setModelValue(this.model, response)

      // if (resp.role !== 'petugas-tps') {
      //   this.model.work_area_village_id.type = 'hidden'
      //   this.model.tps.type = 'hidden'
      // }
      }).catch(error => {
        if (process.env.NODE_ENV !== 'production') {
          console.error(error)
        }
        this.isLoading = false
      })
    }
  }
}
</script>