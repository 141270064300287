<template>
  <div>
    <template v-if="$store.getters['account/accountData'].role === 'staff-calon' && typeof this.$route.query.caleg_id === 'undefined'">
      <p class="text-center">Memuat data pendukung calon kepala daerah ...</p>
    </template>
    <template v-else>
      <selected-caleg v-if="typeof this.$route.query.caleg_id !== 'undefined' && typeof this.$route.query.parliament_region_id !== 'undefined'"/>
      <selected-parliament-region v-else-if="typeof this.$route.query.parliament_region_id !== 'undefined'"/>
      <parliament-region-options v-else/>
    </template>
  </div>
</template>

<script>
import selectedCaleg from './SelectedCaleg.vue'
import selectedParliamentRegion from './SelectedParliamentRegion.vue'
import parliamentRegionOptions from './ParliamentRegionOptions.vue'

export default {
  components: {
    selectedParliamentRegion,
    selectedCaleg,
    parliamentRegionOptions
  },
  data () {
    return {
      isLoading: false
    }
  },
  methods: {
    async setParams () {
      if (this.$store.getters['account/accountData'].role === 'staff-calon') {
        const { data } = await this.$http.get(process.env.VUE_APP_API_URL + 'staff-calon/show-calon-detail?show_dapil=1')
        this.$router.push({
          path: '/pendukung',
          query: {
            caleg_id: data.user_id,
            dapil_id: data.dapil_id,
            parliament_region_id: data.parliament_region_id,
          }
        }).catch(err => {})
        this.parentCalon = data
      } else if (this.$store.getters['account/accountData'].role !== 'caleg') {} else {
        this.$router.push({
          path: '/pendukung',
          query: {
            caleg_id: this.$store.getters['account/accountData'].id,
            dapil_id: this.$store.getters['account/accountData'].dapil.id,
            parliament_region_id: this.$store.getters['account/accountData'].dapil.parliament_region_id,
          }
        }).catch(err => {})
      }
    }
  },
  updated () {
    this.setParams()
  },
  mounted () {
    this.setParams()
  }
}
</script>