<template>
    <b-card no-header>
        <ValidationObserver ref="form">
          <form @submit.prevent="onSubmit">
            <template v-for="(m, index) in filterModel($store.getters['account/accountData'].role !== 'caleg' ? ['name', 'username', 'password'] : ['name', 'username', 'password', 'avatar'])">
                <div :key="index">
                  <form-group-input :ref="index" :type="m.type" :label="typeof m.label === 'string' ? m.label : null" :options="getOptions(index)" :need-label="true" v-model="m.value" :rules="typeof m.rules !== 'undefined' ? m.rules : null" :disabled="typeof m.disabled !== 'undefined' ? m.disabled : null" :id="index" />
                  <small v-if="index === 'password'" style="margin-top: -1rem !important; display: block;" class="mb-3">Kosongkan jika tidak ingin mengubah password.</small>
                </div>
              </template>
              <div>
                <b-button type="submit" variant="primary" class="mt-0"><font-awesome-icon icon="spinner" spin v-if="isLoading"></font-awesome-icon> Simpan</b-button>
                <b-button :to="'/'" variant="secondary" class="ml-3 float-right"><font-awesome-icon icon="spinner" spin v-if="isLoading"></font-awesome-icon> Batal</b-button>
              </div>
            </form>
        </ValidationObserver>
      </b-card>
</template>

<script>
import { saveProcess, setModelValue, scrollToError, objectToOptions } from '@/_helpers'
import formGroupInput from '@/components/FormGroupInput.vue'
import User from '@/models/User.js'

const userModel = new User()

export default {
  components: {
    formGroupInput
  },
  watch: {
    '$route.params.id': function (val) {
      if (this.mode === 'update') {
        this.getDetail()
      }
    }
  },
  data () {
    return {
      isLoading: false,
      model: userModel.init()
    }
  },
  mounted () {
    this.model.password.rules = 'min:5'
    // this.model.role.type = 'hidden'
    // this.model.role.rules = null
    this.getDetail()
  },
  methods: {
    getOptions (key) {
      return typeof this.model[key].options !== 'undefined' ? objectToOptions(this.model[key].options) : null
    },
    filterModel (search) {
      const filtered = Object.entries(this.model).filter(mdl => {
        return search.includes(mdl[0])
      });
      let result = {}
      filtered.forEach(element => {
        result[element[0]] = element[1];
      });
      return result;
    },
    getDetail () {
      this.$http.get(process.env.VUE_APP_API_URL + 'me').then(resp => {
        setModelValue(this.model, resp.data)
        Object.keys(this.model).forEach(key => {
          if (typeof this.$refs[key] !== 'undefined') {
            this.$refs[key][0].setValue(this.model[key].value)
          }
        })
      }).catch(error => {
        if (process.env.NODE_ENV !== 'production') {
          console.error(error)
        }
      })
    },
    resetForm () {
      this.$nextTick(() => {
        // clearModelValue(this.model)
        Object.keys(this.model).forEach(key => {
          this.$refs[key][0].setValue(null)
        })
        this.$refs.form.reset()
      })
    },
    onSubmit () {
      this.$refs.form.validate().then(success => {
        if (!success || this.isLoading) {
          scrollToError(this.$refs.form)
          return false
        }
        saveProcess(this.model, true).then(model => {
          this.isLoading = true
          // delete model.avatar;
          userModel.updateProfile(model).then(() => {
            this.isLoading = false
            this.$store.dispatch('account/setAccountData')
            this.$store.dispatch('notification/success', 'Data akun berhasil diubah.')
          }).catch(error => {
            this.isLoading = false
            this.$store.dispatch('notification/error', error)
          })
        }).catch(err => {
          console.log(err)
        })
      })
    }
  }
}
</script>