<template>
  <div>
    <back-button default-to="/caleg"/>
    <b-card no-header no-footer>
        <ValidationObserver ref="form">
          <form @submit.prevent="onSubmit">
            <div v-if="detail.parliamentRegion" class="d-flex mb-2">
              <h4>{{ detail.parliamentRegion.name }}</h4>
            </div>
              <b-row>
                <b-col cols="12" md="12">
                  <h5>Informasi Akun</h5>
                  <b-form-checkbox v-model="give_access" name="give_access" :value="true" :unchecked-value="false">Beri Akses Ke Caleg</b-form-checkbox>
                  <div v-if="give_access">
                    <template v-for="(m, index) in filterModel(['username', 'password'])">
                      <div :key="'akses_' + index">
                        <div v-if="index === 'work_area_district_id'">
                          <form-group-input v-show="userModel.work_area_city_id.value !== null" :ref="index" :type="m.type" :label="typeof m.label === 'string' ? m.label : null" :options="m.type === 'radio' ? getClassicOptions(index) : getOptions(index)" :need-label="true" v-model="m.value" :rules="typeof m.rules !== 'undefined' ? m.rules : null" :disabled="typeof m.disabled !== 'undefined' ? m.disabled : null" :id="index" />
                        </div>
                        <div v-else-if="index === 'work_area_village_id'">
                          <form-group-input v-show="userModel.work_area_district_id.value !== null" :ref="index" :type="m.type" :label="typeof m.label === 'string' ? m.label : null" :options="m.type === 'radio' ? getClassicOptions(index) : getOptions(index)" :need-label="true" v-model="m.value" :rules="typeof m.rules !== 'undefined' ? m.rules : null" :disabled="typeof m.disabled !== 'undefined' ? m.disabled : null" :id="index" />
                        </div>
                        <form-group-input v-else :ref="index" :type="m.type" :label="typeof m.label === 'string' ? m.label : null" :options="m.type === 'radio' ? getClassicOptions(index) : getOptions(index)" :need-label="true" v-model="m.value" :rules="typeof m.rules !== 'undefined' ? m.rules : null" :disabled="typeof m.disabled !== 'undefined' ? m.disabled : null" :id="index" />
                        <small v-if="mode === 'update' && index === 'password'" style="margin-top: -1rem !important; display: block;" class="mb-3">Kosongkan jika tidak ingin mengubah password.</small>
                      </div>
                    </template>
                  </div>
                  <!-- <h5 class="mt-5">Informasi Caleg</h5>
                  <template v-for="(m, index) in filterModel(['party_id', 'work_area_city_id', 'dapil_id', 'number'])">
                    <div :key="'tugas_' + index">
                      <detail-group-input :ref="'view_'+ index" :type="m.type" :label="typeof m.label === 'string' ? m.label : null" :need-label="true" v-model="m.value" :id="index" />
                    </div>
                  </template> -->
                  <!-- <template v-for="(m, index) in filterModel(detail.parliamentRegion && detail.parliamentRegion.level === 3 ? ['party_id', 'dapil_id', 'work_area_city_id', /* 'work_area_district_id', 'work_area_village_id', */ 'number'] : ['party_id', 'dapil_id',  'number'])">
                    <div :key="'tugas_' + index">
                      <div v-if="index === 'work_area_district_id'">
                        <form-group-input v-show="userModel.work_area_city_id.value !== null" :ref="index" :type="m.type" :label="typeof m.label === 'string' ? m.label : null" :options="m.type === 'radio' ? getClassicOptions(index) : getOptions(index)" :need-label="true" v-model="m.value" :rules="typeof m.rules !== 'undefined' ? m.rules : null" :disabled="typeof m.disabled !== 'undefined' ? m.disabled : null" :id="index" />
                      </div>
                      <div v-else-if="index === 'work_area_village_id'">
                        <form-group-input v-show="userModel.work_area_district_id.value !== null" :ref="index" :type="m.type" :label="typeof m.label === 'string' ? m.label : null" :options="m.type === 'radio' ? getClassicOptions(index) : getOptions(index)" :need-label="true" v-model="m.value" :rules="typeof m.rules !== 'undefined' ? m.rules : null" :disabled="typeof m.disabled !== 'undefined' ? m.disabled : null" :id="index" />
                      </div>
                      <form-group-input v-else :ref="index" :type="m.type" :label="typeof m.label === 'string' ? m.label : null" :options="m.type === 'radio' ? getClassicOptions(index) : getOptions(index)" :need-label="true" v-model="m.value" :rules="typeof m.rules !== 'undefined' ? m.rules : null" :disabled="typeof m.disabled !== 'undefined' ? m.disabled : null" :id="index" />
                    </div>
                    
                  </template> -->
                </b-col>
                <b-col cols="12" md="12" class="mt-4">
                  <h5>Informasi Pribadi</h5>
                  <template v-for="(m, index) in filterModel(['name', 'nik', 'gender', 'hometown', 'dob', 'city_id', 'district_id', 'village_id', 'address', 'phone_number', 'avatar', 'id_card'])">
                    <div :key="'pribadi_' + index">
                      <div v-if="index === 'district_id'">
                        <form-group-input v-show="userModel.city_id.value !== null" :ref="index" :type="m.type" :label="typeof m.label === 'string' ? m.label : null" :options="m.type === 'radio' ? getClassicOptions(index) : getOptions(index)" :need-label="true" v-model="m.value" :rules="typeof m.rules !== 'undefined' ? m.rules : null" :disabled="typeof m.disabled !== 'undefined' ? m.disabled : null" :id="index" />
                      </div>
                      <div v-else-if="index === 'village_id'">
                        <form-group-input v-show="userModel.district_id.value !== null" :ref="index" :type="m.type" :label="typeof m.label === 'string' ? m.label : null" :options="m.type === 'radio' ? getClassicOptions(index) : getOptions(index)" :need-label="true" v-model="m.value" :rules="typeof m.rules !== 'undefined' ? m.rules : null" :disabled="typeof m.disabled !== 'undefined' ? m.disabled : null" :id="index" />
                      </div>
                      <form-group-input v-else :ref="index" :type="m.type" :label="typeof m.label === 'string' ? m.label : null" :options="m.type === 'radio' ? getClassicOptions(index) : getOptions(index)" :need-label="true" v-model="m.value" :rules="typeof m.rules !== 'undefined' ? m.rules : null" :disabled="typeof m.disabled !== 'undefined' ? m.disabled : null" :id="index" />
                      <small v-if="index === 'id_card'" style="margin-top: -1rem !important; display: block;" class="mb-3">Ukuran file maksimal 750kb.</small>
                      <small v-else-if="index === 'avatar'" style="margin-top: -1rem !important; display: block;" class="mb-3">Ukuran file maksimal 500kb, dimensi foto disarankan 350px x 350px.</small>
                    </div>
                    
                  </template>
                </b-col>
              </b-row>
              <div>
                <b-button type="submit" variant="primary" class="mt-0"><font-awesome-icon icon="spinner" spin v-if="isLoading"></font-awesome-icon> Simpan</b-button>
                <b-button :to="'/caleg' + (parliament_region_id ? '?parliament_region_id=' + parliament_region_id : '')" variant="secondary" class="ml-3 float-right"><font-awesome-icon icon="spinner" spin v-if="isLoading"></font-awesome-icon> Batal</b-button>
              </div>
            </form>
        </ValidationObserver>
    </b-card>
  </div>
</template>

<script>
import { saveProcess, setModelValue, scrollToError, objectToOptions, classicObjectToOptions } from '@/_helpers'
import formGroupInput from '@/components/FormGroupInput.vue'
import User from '@/models/User.js'
import City from '@/models/City.js'
import District from '@/models/District.js'
import Village from '@/models/Village.js'
import Party from '@/models/Party.js'
import ParliamentRegion from '@/models/ParliamentRegion.js'
import Dapil from '@/models/Dapil.js'
import detailGroupInput from '@/components/DetailGroupInput.vue'

const userModel = new User()
const cityModel = new City()
const districtModel = new District()
const villageModel = new Village()
const partyModel = new Party()
const parliamentRegionModel = new ParliamentRegion()
const dapilModel = new Dapil()

export default {
  components: {
    formGroupInput,
    detailGroupInput
  },
  watch: {
    '$route.params.id': function (val) {
      if (this.mode === 'update') {
        this.getDetail()
      }
    },
    // '$route.query.parliament_region_id': function (val) {
    //   this.getParliamentRegionDetail()
    // },
    'userModel.city_id.value': async function (val, oldVal) {
      if (val) {
        this.userModel.district_id.options = await this.getDistricts(val)
        this.totalPromiseAreaCount++;
        if (val !== oldVal && !this.initEdit) {
          this.userModel.district_id.value = null
        }
      } else {
        this.userModel.district_id.value = null
      }
    },
    'userModel.district_id.value': async function (val, oldVal) {
      if (val) {
        this.userModel.village_id.options = await this.getVillages(val)
        this.totalPromiseAreaCount++;
        if (val !== oldVal && !this.initEdit) {
          this.userModel.village_id.value = null
        }
      } else {
        this.userModel.village_id.value = null
      }
    },
    'userModel.work_area_city_id.value': async function (val, oldVal) {
      if (val) {
        // this.userModel.work_area_district_id.options = await this.getDistricts(val)
        // this.userModel.dapil_id.options = await this.getDapil(val, this.detail.parliamentRegion.level)
        this.totalPromiseAreaCount++;
        if (val !== oldVal && !this.initEdit) {
          this.userModel.work_area_district_id.value = null
          this.userModel.dapil_id.value = null
        }
      } else {
        this.userModel.work_area_district_id.value = null
        this.userModel.dapil_id.value = null
      }
    },
    'userModel.work_area_district_id.value': async function (val, oldVal) {
      if (val) {
        // this.userModel.work_area_village_id.options = await this.getVillages(val)
        this.totalPromiseAreaCount++;
        if (val !== oldVal && !this.initEdit) {
          this.userModel.work_area_village_id.value = null
        }
      } else {
        this.userModel.work_area_village_id.value = null
      }
    },
    'totalPromiseAreaCount': async function (val) {
      if (val >= 4) {
        this.initEdit = false;
      }
    },
  },
  computed: {
    mode: function () {
      return this.$route.params.id !== undefined ? 'update' : 'create'
    }
  },
  data () {
    return {
      give_access: false,
      totalPromiseAreaCount: 0,
      initEdit: false,
      isLoading: false,
      userModel: userModel.init(),
      parliament_region_id: typeof this.$route.query.parliament_region_id !== 'undefined' ? this.$route.query.parliament_region_id : null,
      detail: {
        parliamentRegion: null
      },
    }
  },
  async mounted () {
    if (typeof this.$route.query.parliament_region_id !== 'undefined' ) {
      // await this.getParliamentRegionDetail()
    }
    if (this.mode === 'update') {
      this.userModel.password.rules = null
      this.getDetail()
    } else {
      this.userModel.role.value = 'caleg'
    }
    this.getCities()
    // this.getParties()
  },
  methods: {
    async getParliamentRegionDetail () {
      return new Promise(resolve => {
        parliamentRegionModel.find(this.parliament_region_id).then(async resp => {
          this.detail.parliamentRegion = resp

          // if (resp.level !== 3) {
          //   this.userModel.dapil_id.options = await this.getDapil('', resp.level)
          // }
          return resolve('');
          // this.userModel.dapil_id.options = objectToOptions(await this.getDapil(resp.level === 3 ? this.$route.query.work_area_city_id : '', resp.level))
        })
      })
    },
    async getDapil (cityId, regionLevel = 3) {
      if (regionLevel === 3 && !cityId) {
        return []
      }
      if (regionLevel !== 3) {
        cityId = ''
      }
      return new Promise(resolve => {
        dapilModel.list({
          'region_city_id': cityId,
          'parliament_region_level': regionLevel,
          'limit': 200
        }).then(resp => {
          let data = []
          // data[null] = 'Tidak Ada'
          resp.data.forEach(d => {
            data[d.id] = d.name
          })
          resolve(data);
          // this.userModel.village_id.options = data
        })
      })
    },
    filterModel (search) {
      const filtered = Object.entries(this.userModel).filter(mdl => {
        return search.includes(mdl[0])
      });
      let result = {}
      filtered.forEach(element => {
        result[element[0]] = element[1];
      });
      return result;
    },
    getParties () {
      partyModel.list({
        'limit': 10000
      }).then(resp => {
        let data = []
        resp.data.forEach(d => {
          data[d.id] = d.name
        })
        this.userModel.party_id.options = data
      })
    },
    getCities () {
      cityModel.list({
        'registered_only': 1,
        // 'name': 'labuhan',
        'province_id': '950b9d3a-c996-4207-97e7-5a2858981979',
        'limit': 10000
      }).then(resp => {
        let data = []
        // data[null] = 'Tidak Ada'
        resp.data.forEach(d => {
          data[d.id] = d.name
        })
        this.userModel.city_id.options = data
        // this.userModel.work_area_city_id.options = data
      })
    },
    async getDistricts (cityId) {
      return new Promise(resolve => {
        districtModel.list({
          'city_id': cityId
        }).then(resp => {
          let data = []
          // data[null] = 'Tidak Ada'
          resp.data.forEach(d => {
            data[d.id] = d.name
          })
          resolve(data);
          // this.userModel.district_id.options = data
        })
      })
    },
    async getVillages (districtId) {
      return new Promise(resolve => {
        villageModel.list({
          'district_id': districtId,
        }).then(resp => {
          let data = []
          // data[null] = 'Tidak Ada'
          resp.data.forEach(d => {
            data[d.id] = d.name
          })
          resolve(data);
          // this.userModel.village_id.options = data
        })
      })
    },
    getOptions (key) {
      return typeof this.userModel[key].options !== 'undefined' ? objectToOptions(this.userModel[key].options) : null
    },
    getClassicOptions (key) {
      return typeof this.userModel[key].options !== 'undefined' ? classicObjectToOptions(this.userModel[key].options) : null
    },
    getDetail () {
      this.initEdit = true
      this.isLoading = true
      userModel.find(this.$route.params.id, {
        caleg_detail: 1
      }).then(async resp => {
        if (resp.user_work_area.dapil_id) {
          const dapil = await dapilModel.find(resp.user_work_area.dapil_id)
          this.parliament_region_id = dapil.parliament_region_id
          await this.getParliamentRegionDetail()
        }
        this.isLoading = false
        let response = {}
        // response = resp
        Object.keys(resp.user_detail).forEach(key => {
          if (!['user_id', 'village', 'city', 'district', 'province', 'avatar', 'id_card'].includes(key)) {
            response[key] = resp.user_detail[key]
          }
        });
        Object.keys(resp.user_work_area).forEach(key => {
          if (!['user_id', 'village', 'city', 'district', 'province'].includes(key)) {
            response[key] = resp.user_work_area[key]
          }
        });
        Object.keys(resp).forEach(key => {
          if (!['user_detail', 'user_work_area', 'role'].includes(key)) {
            response[key] = resp[key]
          }
        });
        response['phone_number'] = response['phone_number'] ? response['phone_number'].replace('+62', '0') : '';
        setModelValue(this.userModel, response)
        Object.keys(this.userModel).forEach(key => {
          if (!['avatar', 'id_card', 'role'].includes(key) && typeof this.$refs[key] !== 'undefined') {
            this.$refs[key][0].setValue(this.userModel[key].value)
          }
        })
        this.give_access = this.userModel.username.value !== null

        this.userModel.party_id.disabled = true
        this.userModel.work_area_city_id.disabled = true
        this.userModel.dapil_id.disabled = true
        this.userModel.number.disabled = true

        this.$refs['view_party_id'][0].setValue(resp.user_work_area.party.name)
        this.$refs['view_dapil_id'][0].setValue(resp.user_work_area.dapil.name)
        if (!resp.user_work_area.work_area_city_id) {
          this.userModel.work_area_city_id.type = 'hidden'
        } else {
          this.$refs['view_work_area_city_id'][0].setValue(resp.user_work_area.city.name)
        }
      }).catch(error => {
        if (process.env.NODE_ENV !== 'production') {
          console.error(error)
        }
        // this.initEdit = false
        this.isLoading = false
      })
    },
    resetForm () {
      this.$nextTick(() => {
        // clearModelValue(this.userModel)
        Object.keys(this.userModel).forEach(key => {
          this.$refs[key][0].setValue(null)
        })
        this.$refs.form.reset()
      })
    },
    onSubmit () {
      this.$refs.form.validate().then(success => {
        if (!success || this.isLoading) {
          scrollToError(this.$refs.form)
          return false
        }
        if (!this.give_access) {
         this.userModel.username.value = null; 
         this.userModel.revoke_access.value = 1; 
          if (this.mode === 'update') {
            this.userModel.password.value = null; 
          } else {
            this.userModel.password.value = null; 
          }
        } else {
          this.userModel.revoke_access.value = null; 
        }
        saveProcess(this.userModel, this.mode === 'update').then(model => {
          this.isLoading = true
          if (this.mode === 'update') {
            userModel.update(this.$route.params.id, model).then(() => {
              this.isLoading = false
              this.$store.dispatch('notification/success', 'Data user berhasil diubah.')
              if (!this.give_access) {
                this.$router.push('/caleg')
              } else {
                this.$router.push('/calon/' + this.$route.params.id)
              }
            }).catch(error => {
              this.isLoading = false
              this.$store.dispatch('notification/error', error)
            })
          } else {
            userModel.create(model).then(resp => {
              this.isLoading = false
              // this.resetForm()
              this.$store.dispatch('notification/success', 'Data user berhasil disimpan.')
              this.$router.push('/calon/' + resp.id)
            }).catch(error => {
              this.isLoading = false
              this.$store.dispatch('notification/error', error)
            })
          }
        }).catch(err => {
          console.error(err)
        })
      })
    }
  }
}
</script>