<template>
  <div>
    <selected-parliament-region v-if="typeof this.$route.query.parliament_region_id !== 'undefined'"/>
    <parliament-region-options v-else/>
  </div>
</template>

<script>
import selectedParliamentRegion from './SelectedParliamentRegion.vue'
import parliamentRegionOptions from './ParliamentRegionOptions.vue'

export default {
  components: {
    selectedParliamentRegion,
    parliamentRegionOptions
  },
}
</script>