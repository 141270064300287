import axios from 'axios'

export default class CalegCountSummary {
    endpoint = process.env.VUE_APP_API_URL + 'caleg-count-summary'

    totalVote (calegId, groupType, groupId) {
        return new Promise((resolve, reject) => {
        axios.get(this.endpoint + '/total-vote?caleg_id=' + calegId + '&group_type=' + groupType + '&group_id=' + groupId).then(response => {
            resolve(response.data ? [
            response.data
            ] : [])
        }).catch(error => {
            reject(error)
        })
        })
    }
    totalVoteByDapilId (dapilId) {
        return new Promise((resolve, reject) => {
        axios.get(this.endpoint + '/total-vote-by-dapil-id?dapil_id=' + dapilId).then(response => {
            resolve(response.data ? [
            response.data
            ] : [])
        }).catch(error => {
            reject(error)
        })
        })
    }
}