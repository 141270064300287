import axios from 'axios'
import BaseModel from '@/models/BaseModel.js'

export default class User extends BaseModel {
    fields =  {
      'name': {
        label: 'Nama',
        type: 'text',
        rules: 'required'
      },
      'nik': {
        type: 'text',
        label: 'NIK',
        rules: 'required'
      },
      'gender': {
        type: 'radio',
        label: 'Jenis Kelamin',
        rules: 'required',
        options: {
          'laki-laki': 'Laki-Laki',
          'perempuan': 'Perempuan',
        }
      },
      'hometown': {
        type: 'text',
        label: 'Tempat Lahir',
        rules: 'required'
      },
      'dob': {
        type: 'date',
        label: 'Tanggal Lahir',
        rules: 'required'
      },
      'city_id': {
        type: 'enum',
        label: 'Kabupaten',
        rules: 'required',
        options: {}
      },
      'district_id': {
        type: 'enum',
        label: 'Kecamatan',
        rules: 'required',
        options: {}
      },
      'village_id': {
        type: 'enum',
        label: 'Kelurahan',
        rules: 'required',
        options: {}
      },
      'address': {
        type: 'textarea',
        label: 'Alamat',
        rules: 'required'
      },
      'phone_number': {
        type: 'text',
        label: 'No. HP',
        rules: 'required'
      },
      'avatar': {
        type: 'image',
        rules: null,
        label: 'Foto',
      },
      'id_card': {
        type: 'image',
        label: 'Scan KTP',
        rules: null,
      },
      'role': {
        label: 'Peran',
        type: 'enum',
        options: {
          'petugas-tps': 'Petugas TPS',
          'verifikator': 'Verifikator',
        },
        rules: 'required'
      },
      'username': {
        type: 'text',
        rules: 'required'
      },
      'password': {
        type: 'password',
        rules: 'required'
      },
      'party_id': {
        type: 'enum',
        rules: 'required',
        label: 'Partai',
        options: {}
      },
      'work_area_city_id': {
        type: 'enum',
        label: 'Kabupaten',
        rules: 'required',
        options: {}
      },
      'dapil_id': {
        type: 'enum',
        label: 'Daerah',
        rules: 'required',
        options: {}
      },
      // 'work_area_district_id': {
      //   type: 'enum',
      //   label: 'Kecamatan',
      //   rules: 'required',
      //   options: {}
      // },
      // 'work_area_village_id': {
      //   type: 'enum',
      //   label: 'Kelurahan',
      //   rules: 'required',
      //   options: {}
      // },
      'tps': {
        label: 'TPS',
        type: 'number',
        rules: 'required'
      },
      'number': {
        type: 'number',
        label: 'No. Urut',
        rules: 'required'
      },
      'revoke_access': {
        type: 'hidden',
        rules: null
      },
    }
    endpoint = process.env.VUE_APP_API_URL + 'users'

    updateProfile (value) {
      return new Promise((resolve, reject) => {
        axios.post(process.env.VUE_APP_API_URL + 'setting', this.initForEndpoint(value)).then(response => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error)
        })
      })
    }
}