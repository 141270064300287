<template>
  <div>
    <back-button default-to="/perolehan-suara"/>
    <b-card no-header>
      <b-row class="mb-4">
        <!-- <b-col cols="6" md="2">
          <h2 class="m-0 text-md-center text-lg-center" style="font-weight: bold;">
            <img v-if="party.logo && Object.keys(party.logo).length > 0" :src="party.logo['']" alt="" style="max-width: 70px;"> {{ party.name }}
          </h2>
        </b-col> -->
        <b-col cols="6" class="text-right d-md-none d-lg-none">
          <b-button-group class="add-button-group">
            <b-btn variant="success" type="button" @click.prevent="doExport"><font-awesome-icon icon="file-excel"/> Ekspor</b-btn>
          </b-button-group>
        </b-col>
        <b-col cols="12" md="6" class="d-flex align-items-center flex-wrap mt-2 mt-md-0">
          <p class="mb-1 w-100">Nama Calon: {{ caleg.name }}<br>No. urut:  {{ caleg.number }}<br>DAERAH:  {{ caleg.dapil_name }}<br>Total Suara:  {{ caleg.vote_count }}</p>
        </b-col>
        <b-col cols="5" class="align-items-center flex-wrap justify-content-end d-none d-md-flex">
          <b-button-group class="add-button-group">
            <b-btn variant="success" type="button" @click.prevent="doExport"><font-awesome-icon icon="file-excel"/> Ekspor</b-btn>
          </b-button-group>
        </b-col>
        <!-- <b-col cols="10">
          <b-row>
            <b-col cols="12" class="text-right">
              <b-button-group class="add-button-group">
                <b-btn variant="success" type="button"><font-awesome-icon icon="file-excel"/> Ekspor</b-btn>
              </b-button-group>
            </b-col>
            <b-col cols="12" class="text-right">
              <b-form>
                <b-row class="filter-row justify-content-end">
                  <b-col cols="4">
                    <form-group-input ref="filter-name" type="text" placeholder="" :need-label="true" label="cari nama caleg" id="filter-name" class="text-left" />
                  </b-col>
                  <b-col cols="2">
                    <form-group-input ref="filter-tps" type="text" class="text-left" placeholder="" :need-label="true" label="TPS" id="filter-tps" />
                  </b-col>
                  <b-col cols="3" style="max-width: 165px;">
                    <label class="form-label">&nbsp;</label><br>
                    <b-btn type="submit" variant="primary" class="mr-2">Cari</b-btn>
                    <b-btn type="button" variant="secondary">Clear</b-btn>
                  </b-col>
                </b-row>
              </b-form>

            </b-col>
          </b-row>
        </b-col> -->

        <b-col cols="12" class="mb-1 mt-4">
          <b-form @submit.prevent="doFilter">
            <b-row class="filter-row">
              <b-col cols="6" md="3">
                <form-group-input ref="filter-city_id" type="enum" :options="list.city" placeholder="" :need-label="true" label="Kota/Kabupaten" v-model="filter.city_id" id="filter-city_id" />
              </b-col>
              <b-col cols="6" md="2">
                <label class="form-label">&nbsp;</label><br>
                <b-btn type="submit" class="btn-in-form" variant="primary">
                  <font-awesome-icon icon="search"/><span>Cari</span>
                </b-btn>
                <b-btn type="button" @click.prevent="clearForm" variant="secondary" class="btn-in-form ml-2">
                  <font-awesome-icon icon="broom"/><span>Clear</span>
                </b-btn>
              </b-col>
            </b-row>
          </b-form>
        </b-col>
      </b-row>
      
      
    <paginated-table v-if="filter.dapil_id" ref="dataTable" :delete-reason="false" :can-delete="true" :can-edit="false" :data-url="apiUrl.district" :data-columns="columns" :dataParams="dataParams" :defaultDataParams="this.filter" :dataLimit="50" :noPushParam="true">
      <template slot="content_name" slot-scope="defaultSlotScope">
        <router-link style="text-decoration: underline;" :to="'/perolehan-suara/calon/' + $route.params.calegId + '/' + defaultSlotScope.colData.id" class="mb-0 text-center detail mr-1">
          {{ defaultSlotScope.colData.name }}
        </router-link>
      </template>
      <template slot="content_total_surat_suara" slot-scope="defaultSlotScope">
        {{ defaultSlotScope.colData.count_stat ? defaultSlotScope.colData.count_stat.total_surat_suara : '-' }}
      </template>
      <template slot="content_total_suara_sah" slot-scope="defaultSlotScope">
        {{ defaultSlotScope.colData.count_stat ? defaultSlotScope.colData.count_stat.total_suara_sah : '-' }}
      </template>
      <template slot="content_total_suara_tidak_sah" slot-scope="defaultSlotScope">
        {{ defaultSlotScope.colData.count_stat ? defaultSlotScope.colData.count_stat.total_suara_tidak_sah : '-' }}
      </template>
      <template slot="content_total_suara_tidak_terpakai" slot-scope="defaultSlotScope">
        {{ defaultSlotScope.colData.count_stat ? defaultSlotScope.colData.count_stat.total_suara_tidak_terpakai : '-' }}
      </template>
      <template slot="content_status" slot-scope="defaultSlotScope">
        {{ defaultSlotScope.colData.count_stat ? (defaultSlotScope.colData.count_stat.verified ? 'Terverifikasi' : 'Belum') : '-' }}
      </template>
      <template slot="actionButtons" slot-scope="defaultSlotScope">
        <b-row class="justify-content-center table-actions">
          <template>
            <b-button variant="primary" size="sm" :to="'/perolehan-suara/calon/' + $route.params.calegId + '/' + defaultSlotScope.colData.id" class="mb-0 text-center detail mr-1">
              <font-awesome-icon icon="info"/>
            </b-button>
          </template>
        </b-row>
      </template>
    </paginated-table>
    </b-card>
  </div>
</template>

<script>
import { objectToOptions } from '@/_helpers'
import formGroupInput from '@/components/FormGroupInput.vue'
import paginatedTable from '@/components/PaginatedTable.vue'
import Party from '@/models/Party.js'
import City from '@/models/City.js'
import District from '@/models/District.js'
import User from '@/models/User.js'

const partyModel = new Party()
const cityModel = new City()
const districtModel = new District()
const userModel = new User()

export default {
  components: {
    paginatedTable,
    formGroupInput
  },
  data () {
    return {
      caleg: {
        name: null,
        number: null,
        dapil_name: 0,
        vote_count: null,
      },
      isLoading: false,
      parties: [],
      party: null,
      city: null,
      list: {
        city: [],
        district: [],
        village: [],
      },
      apiUrl: {
        district: districtModel.endpoint
        // caleg: process.env.VUE_APP_API_URL + 'fake-data/caleg'
      },
      columns: [
        {
          prop: 'name',
          label: 'Kecamatan',
          sortable: true,
          resizable: true,
          minWidth: 160
        },
        {
          prop: 'city_name',
          label: 'Kota',
          sortable: true,
          resizable: true,
          minWidth: 160
        },
        {
          align: 'center',
          prop: 'villages_count',
          label: 'Kelurahan',
          resizable: true,
          sortable: false,
          minWidth: 90
        },
        {
          prop: 'vote_count',
          label: 'Jlh. Suara',
          resizable: true,
          sortable: true,
          align: 'center',
          minWidth: 100
        },
        {
          align: 'center',
          prop: 'total_surat_suara',
          label: 'Total Surat',
          resizable: true,
          sortable: false,
          minWidth: 90
        },
        {
          align: 'center',
          prop: 'total_suara_sah',
          label: 'Sah',
          resizable: true,
          sortable: false,
          minWidth: 90
        },
        {
          align: 'center',
          prop: 'total_suara_tidak_sah',
          label: 'Tdk Sah',
          resizable: true,
          sortable: false,
          minWidth: 90
        },
        {
          align: 'center',
          prop: 'total_suara_tidak_terpakai',
          label: 'Tdk Terpakai',
          resizable: true,
          sortable: false,
          minWidth: 100
        },
        {
          align: 'center',
          prop: 'status',
          label: 'Verifikasi',
          resizable: true,
          sortable: false,
          minWidth: 100
        },
      ],
      filter: {
        'with_villages_count': 1,
        'votes_count': 1,
        'dapil_id': null,
        city_id: typeof this.$route.query.city_id !== 'undefined' ? this.$route.query.city_id : null,
        'for_caleg': this.$route.params.calegId,
      },
      dataParams: ['with_villages_count', 'votes_count', 'dapil_id', 'for_caleg', 'city_id']
    }
  },
  mounted () {
    // this.getPartyDetail()
    this.getCalegDetail()
    // this.getCityDetail()
  },
  watch: {
    '$route.params.partyid': function (val) {
      if (val !== undefined) {
        // this.getPartyDetail()
      }
    },
    '$route.params.cityId': function (val) {
      if (val !== undefined) {
        this.getCityDetail()
      }
    },
  },
  methods: {
    doFilter () {
      this.$refs.dataTable.doFilter(this.filter)
    },
    getCities () {
      cityModel.list({
        // 'name': 'labuhan',
        'dapil_id': this.filter.dapil_id,
        'limit': 10000
      }).then(resp => {
        let data = []
        // data[null] = 'Tidak Ada'
        resp.data.forEach(d => {
          data[d.id] = d.name
        })
        this.list.city = objectToOptions(data)
      })
    },
    getCalegDetail () {
      userModel.find(this.$route.params.calegId, {
        caleg_work_area_only: 1
      }).then(resp => {
        this.filter.dapil_id = resp.user_work_area.dapil.id
        this.caleg.name = resp.name
        this.caleg.number = resp.user_work_area.number
        this.caleg.dapil_name = resp.user_work_area.dapil.name
        this.caleg.vote_count = resp.user_work_area.vote_count
        this.getCities()
      })
    },
    getPartyDetail () {
      partyModel.find(this.$route.params.partyid).then(resp => {
        this.party = resp
      })
    },
    getCityDetail () {
      cityModel.find(this.$route.params.cityId).then(resp => {
        this.city = resp
      })
    },
    doExport () {
      if (this.isLoading) {
        return false;
      }
      // this.isLoading = true;
      const filterArray = [];
      Object.keys(this.filter).forEach(key => {
        if (this.filter[key]) {
          filterArray.push(key + "=" + this.filter[key]);
        }
      });

      filterArray.push("xlsx=1");
      // filterArray.push("caleg_id=" + this.$store.getters["account/accountData"].id);
      filterArray.push(
        "access_token=" + this.$store.getters["account/tokens"].access_token
      );
      window.open(districtModel.getEndpoint() + "?" + filterArray.join("&"), '_blank').focus();
      // filterArray.push('csv=1');

      // this.$http.get(districtModel.getEndpoint() + "?" + filterArray.join("&")).then(response => {
      //   const a = document.createElement("a");

      //   const blob = new Blob([response.data], {type: "octet/stream"});
      //   a.href = window.URL.createObjectURL(blob);

      //   a.download = "data_perolehan_suara_kecamatan_caleg_" + this.caleg.name + ".csv";
      //   a.click();
      //   this.isLoading = false;
      // }).catch(error => {
      //   this.$store.dispatch('notification/error', error)
      //   this.isLoading = false;
      // })
    }
  }
}
</script>