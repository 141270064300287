<template>
    <b-modal ref="usernameModal" :title="!initData.username ? 'Beri Hak Akses Calon': 'Ubah Hak Akses Calon'" hide-footer>
        <form @submit.prevent="doSubmit">
            <div class="mb-3">
                <label for="" class="mb-0">Username</label>
                <b-input type="text" autocomplete="off" placeholder="Ketikkan username calon" required v-model="model.username"/>
            </div>
            <div class="mb-3">
                <label for="" class="mb-0">Password</label>
                <b-input type="password" autocomplete="off" placeholder="Ketikkan password calon" :required="!initData.username" v-model="model.password"/>
                <small v-if="initData.username">Kosongkan jika tidak ingin mengubah password</small>
            </div>
            <b-button variant="primary" :disabled="isLoading" type="submit"><font-awesome-icon icon="spinner" spin v-if="isLoading"></font-awesome-icon> Simpan</b-button>
            <b-button variant="danger" class="float-right" v-if="initData.username" :disabled="isLoading" type="button" @click.prevent="revokeAccess">Hapus Akses</b-button>
        </form>
    </b-modal>
</template>
<script>
import Caleg from '@/models/Caleg.js'

const calegModel = new Caleg()

export default {
  data () {
    return {
        isLoading: false,
        initData: {
            username: null,
        },
        model: {
            id: null,
            name: null,
            username: null,
            password: null,
        },
        list: {
            cities: [],
            dapil: [],
            parties: [],
            users: []
        }
    }
  },
  methods: {
    show (userDetail) {
        this.model.id = this.$route.params.id
        this.model.name = userDetail.name.value
        this.model.username = userDetail.username.value
        this.model.password = null
        this.initData.username = userDetail.username.value
        this.$refs.usernameModal.show()
    },
    hide () {
        this.$refs.usernameModal.hide()
    },
    doSubmit () {
        this.isLoading = true
        let form = {
            username: this.model.username,
            password: this.model.password,
        }
        if (!this.model.password || this.model.password === '') {
            delete form.password
        }
        calegModel.update(this.$route.params.id, form).then(() => {
            this.isLoading = false
            if (!this.initData.username) {
                this.$store.dispatch('notification/success', 'Hak akses caleg berhasil ditambahkan.')
            } else {
                this.$store.dispatch('notification/success', 'Data akses caleg berhasil diubah.')
            }
            this.$emit('submitted', {
                username: this.model.username
            })
            this.hide()
        }).catch(error => {
            this.isLoading = false
            this.$store.dispatch('notification/error', error)
        })
    },
    revokeAccess () {
        this.isLoading = true
        calegModel.update(this.$route.params.id, {
            revoke_access: 1
        }).then(() => {
            this.isLoading = false
            this.$store.dispatch('notification/success', 'Hak akses caleg berhasil dihapus.')
            this.$emit('submitted', {
                username: null
            })
            this.hide()
        }).catch(error => {
            this.isLoading = false
            this.$store.dispatch('notification/error', error)
        })
    }
  }
}
</script>