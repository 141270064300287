<template>
  <div>
    <div v-if="multipleSelectVal.length" class="bottom-confirmation">
        <span>Hapus {{multipleSelectVal.length}} data?</span>
        <b-button :disabled="deleteLoading" type="button" class="mr-3" variant="danger" @click="deleteMultipleData"><font-awesome-icon v-if="deleteLoading" icon="spinner" spin /> <font-awesome-icon v-else icon="check"/> Ya</b-button>
        <b-button :disabled="deleteLoading" type="button" class="text-white" variant="primary" @click="$refs.paginatedTable.clearSelection()"><font-awesome-icon icon="times"/> Tidak</b-button>
    </div>
    <b-row>
      <div class="col-sm-12" id="paginated-table">
        <b-progress v-if="isLoading" :value="100" height="20" class="mb-2" variant="primary" :animated="true" :striped="true"></b-progress>
        <div v-show="failedToGetData" class="refresh-modal">
          <p>Failed To Get Data</p>
          <div class="refresh-button text-center">
            <button type="button" @click="getDataTable()" class="btn btn-primary"><font-awesome-icon icon="sync-alt"/> Reload</button>
          </div>
        </div>
        <!-- <p class="mb-1 mt-2">Total {{pagination.total}} data</p> -->
        <!-- <el-table ref="paginatedTable" stripe style="width: 100%;" :data="tableData" @sort-change="onSortChange" :default-sort="{prop: pagination.order, order: pagination.ascending ? 'ascending' : 'descending'}"> -->
        <el-table ref="paginatedTable" :max-height="maxHeight" border stripe style="width: 100%;" :data="tableData" @sort-change="onSortChange" empty-text="Data tidak ditemukan" @selection-change="handleSelectionChange" :row-class-name="tableRowClassName">
          <el-table-column v-if="needSelect" type="selection" align="center"></el-table-column>
          <el-table-column v-for="column in tableColumns" :key="column.label" :min-width="column.minWidth"  :width="column.width? column.width : null" :prop="column.prop" :label="column.label" :resizable="column.resizable" :align="column.isToggle ? 'center' : column.align" :fixed="column.fixed">
            <template slot="header" slot-scope="slot">
              <div v-if="column.sortable"  class="pointer lh-normal p-0" @click="doSorting(column.prop, column.localSortable)">
              {{column.label}} <font-awesome-icon v-if="pagination.order !== column.prop" icon="sort"/> <font-awesome-icon v-else-if="pagination.order === column.prop && pagination.ascending === 0" icon="sort-up"/> <font-awesome-icon v-else-if="pagination.order === column.prop && pagination.ascending === 1" icon="sort-down"/>
              </div>
              <div v-else class="lh-normal p-0">
              {{column.label}}
              </div>
            </template>
            <template slot-scope="scope">
              <slot v-if="column.hasCustomSlot" :name="'content_' + column.prop" :col-data="scope.row"/>
              <template v-else-if="column.needCurrency"><div :class="'text-' + column.align">{{scope.row[column.prop] !== null ? formatCurrency(scope.row[column.prop]) : '-'}}</div></template>
              <template v-else-if="column.isNumeric">{{scope.row[column.prop] !== null ? parseFloat(scope.row[column.prop]) + 0 : '-'}}</template>
              <template v-else-if="column.formatThisDate">{{scope.row[column.prop] !== null ? formatDate(scope.row[column.prop]) : '-'}}</template>
              <template v-else-if="column.isToggle"><el-switch :width="30" class="ml-2" v-model="scope.row[column.prop]" type="primary" :active-value="1" :inactive-value="0" @change="changeSwitch(column.prop, column.label, scope.row.id, scope.row[column.prop])"></el-switch></template>
              <template v-else>
                <template v-if="canShow && column.wrapInLink">
                  <router-link :class="'text-' + column.align" :to="getLastPathUrl() + scope.row.id" style="text-decoration: underline;">{{scope.row[column.prop]}}</router-link>
                </template>
                <template v-else>
                  <div :class="'text-' + column.align">{{scope.row[column.prop]}}</div>
                </template>
              </template>
            </template>
          </el-table-column>
          <el-table-column
            v-if="!noActions"
            :width="115"
            align="right"
            fixed="right"
            label="">
            <template slot-scope="scope">
              <template v-if="hasSlot('actionButtons')">
                <slot name="actionButtons" :col-data="scope.row"/>
              </template>
              <template v-if="!hasSlot('actionButtons')">
                <b-row class="justify-content-center table-actions">
                  <template v-if="canShow">
                    <template>
                      <b-button variant="primary" size="sm" :to="getLastPathUrl() + scope.row.id" class="mb-0 text-center detail mr-1">
                        <font-awesome-icon icon="info"/>
                      </b-button>
                    </template>
                  </template>
                  <template v-if="canEdit">
                    <b-button variant="info" size="sm" :to="getLastPathUrl() + scope.row.id + '/edit'" class="mb-0 text-center edit">
                      <font-awesome-icon icon="pencil-alt"/>
                    </b-button>
                  </template>
                  <template v-if="deleteCriteria !== null & applyDeleteCriteria(scope.row) && canDelete">
                    <b-button variant="danger" size="sm" type="button" v-on:click.stop.prevent="doDeleteData(scope.row.id)" class="mb-0 text-center delete ml-1">
                      <font-awesome-icon icon="trash"/>
                    </b-button>
                  </template>
                  <template v-if="deleteCriteria === null & canDelete">
                    <b-button variant="danger" size="sm" type="button" v-on:click.stop.prevent="doDeleteData(scope.row.id)" class="mb-0 text-center delete ml-1">
                      <font-awesome-icon icon="trash"/>
                    </b-button>
                  </template>
                </b-row>
              </template>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="col-12 mt-2 d-flex justify-content-center justify-content-sm-between flex-wrap">
        <div class="w-100">
          <!-- <p class="card-category">Showing {{pagination.from + 1}} to {{pagination.to}} of {{pagination.total}} entries</p> -->
          <b-row>
            <b-col md="10">
              <b-pagination v-if="pagination.total > dataLimit" size="sm" class="pagination-no-border mt-1" @input="onChangePage" v-model="pagination.currentPage" :per-page="dataLimit" :total-rows="pagination.total">
              </b-pagination>
            </b-col>
            <b-col md="2">
              <slot name="footer"></slot>
            </b-col>
          </b-row>
          <p v-if="$store.getters['account/accountData'].role !== 'staff-calon'" :class="{'mb-2' : pagination.total > 1}">Total {{$formatPrice(pagination.total)}} data</p>
        </div>
      </div>
    </b-row>
  </div>
</template>

<script>
  import { formatModelValue } from '@/_helpers'
  export default {
    props: {
      dataUrl: {
        type: String,
        required: true
      },
      deleteUrl: {
        type: String,
        required: false,
        default: null
      },
      // dataKey: {
      //   type: String,
      //   required: true
      // },
      dataColumns: {
        type: Array,
        required: true
      },
      dataOrderField: {
        type: String,
        default: 'created_at'
      },
      dataOrderAsc: {
        type: Number,
        default: 0
      },
      dataLimit: {
        type: Number,
        default: 30
      },
      tableRowClassName: {
        type: Function,
        required: false,
        default() {
          return null
        }
      },
      dataParams: {
        type: Array,
        required: false,
        default: function () {
          return []
        }
      },
      defaultDataParams: {
        type: Object,
        required: false,
        default: function () {
          return {}
        }
      },
      needSelect: {
        type: Boolean,
        required: false,
        default: false
      },
      canEdit: {
        type: Boolean,
        required: false,
        default: true
      },
      canShow: {
        type: Boolean,
        required: false,
        default: true
      },
      canDelete: {
        type: Boolean,
        required: false,
        default: true
      },
      deleteCriteria: {
        type: Object,
        required: false,
        default: null
      },
      noActions: {
        type: Boolean,
        required: false,
        default: false
      },
      noPushParam: {
        type: Boolean,
        required: false,
        default: false
      },
      deleteReason: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    data () {
      return {
        isLoading: false,
        deleteLoading: false,
        failedToGetData: false,
        maxHeight: window.innerHeight - 90,
        pagination: {
          // dataLimit: 20,
          currentPage: this.$route.query.page !== undefined ? parseInt(this.$route.query.page) : 1,
          total: 0,
          order: null,
          ascending: 0
        },
        propsToSearch: ['name', 'email', 'age'],
        tableColumns: [],
        tableData: [],
        multipleSelectVal: []
      }
    },
    watch: {
      'pagination.currentPage': function (newVal) {
        this.$emit('changePage', newVal)
      }
    },
    // computed: {
    //   from () {
    //     return this.dataLimit * (this.pagination.currentPage - 1)
    //   },
    //   to () {
    //     let highBound = this.from + this.dataLimit
    //     if (this.total < highBound) {
    //       highBound = this.total
    //     }
    //     return highBound
    //   }
    // },
    computed: {
      getTableData () {
        return this.tableData
      }
    },
    methods: {
      formatCurrency (value) {
        return formatModelValue('price', value)
      },
      handleSelectionChange(val) {
        this.multipleSelectVal = val
      },
      deleteMultipleData () {
        this.$swal.fire({
          title: 'Anda Yakin?',
          text: 'Data tidak akan dapat dikembalikan lagi!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak',
          // confirmButtonClass: 'btn btn-danger mr-3',
          // cancelButtonClass: 'btn btn-default',
          // buttonsStyling: false
        }).then(result => {
          if (result.value) {
            let ids = []
            this.multipleSelectVal.forEach(multipleSelectVal => {
              ids.push(multipleSelectVal.id)
            })
            this.deleteLoading = true
            this.$http.post((this.deleteUrl !== null ? this.deleteUrl : this.dataUrl) + '/' + ids.join(','), {
              _method: 'DELETE'
            }).then(response => {
              this.$store.dispatch('notification/success', 'Berhasil menghapus.')
              this.getDataTable()
              this.$refs.paginatedTable.clearSelection()
              this.deleteLoading = false
            }).catch(error => {
              this.deleteLoading = false
              this.$store.dispatch('notification/error', 'Gagal menghapus data.')
              console.log(error)
            })
          }
        })
      },
      initData () {
        const t = this
        return {
          params () {
            return new Promise((resolve, reject) => {
              t.pagination.order = t.$route.query.order !== undefined ? t.$route.query.order : t.dataOrderField
              t.pagination.ascending = parseInt(t.$route.query.ascending !== undefined ? t.$route.query.ascending : t.dataOrderAsc)
              resolve('done')
            })
          }
        }
      },
      setData () {
        const t = this
        return {
          params (replaceOnly = false, addititonalData = []) {
            let arrayParams = []
            arrayParams['limit'] = t.dataLimit
            arrayParams['page'] = t.pagination.currentPage
            arrayParams['order'] = t.pagination.order
            arrayParams['ascending'] = t.pagination.ascending
            t.dataParams.forEach((key) => {
              if (addititonalData[key] !== undefined) {
                if (addititonalData[key] !== '' && addititonalData[key] !== null && addititonalData[key] !== 'null' && addititonalData[key] !== '') {
                  arrayParams[key] = typeof addititonalData[key] === 'object' ? addititonalData[key].join() : addititonalData[key]
                }
              } else if (t.$route.query[key] !== undefined && t.$route.query[key] !== null && t.$route.query[key] !== '' && t.$route.query[key] !== 'null') {
                arrayParams[key] = typeof t.$route.query[key] === 'object' ? t.$route.query[key].join() : t.$route.query[key]
              } else if (t.defaultDataParams[key] !== undefined && t.defaultDataParams[key] !== null && t.defaultDataParams[key] !== '' && t.defaultDataParams[key] !== 'null') {
                arrayParams[key] = typeof t.defaultDataParams[key] === 'object' ? t.defaultDataParams[key].join() : t.defaultDataParams[key]
              }
            })
            if (!t.noPushParam) {
              t.$router.replace({query: arrayParams}).catch(err => {})
            }
            if (!replaceOnly) {
              let stringParams = ''
              let urlParam = ''
              Object.keys(arrayParams).forEach((key) => {
                if (key !== 'limit' && key !== 'page') {
                  urlParam += (urlParam === '' ? '?' : '&') + key + '=' + arrayParams[key]
                }
                stringParams += (stringParams === '' ? '?' : '&') + key + '=' + arrayParams[key]
              })
              /* t.dataParams.forEach((key) => {
                if (addititonalData[key] !== undefined) {
                  if (addititonalData[key] !== '' && addititonalData[key] !== null) {
                    stringParams += '&' + key + '=' + addititonalData[key]
                  }
                } else if (t.$route.query[key] !== undefined) {
                  stringParams += '&' + key + '=' + t.$route.query[key]
                } else if (t.defaultDataParams[key] !== undefined) {
                  stringParams += '&' + key + '=' + t.defaultDataParams[key]
                }
              }) */
              t.$emit('getBackUrlParams', urlParam)
              return stringParams
            }
          }
        }
      },
      initTableColumns () {
        this.tableColumns = []
        for (let i = 0; i < this.dataColumns.length; i++) {
          this.tableColumns.push({
            prop: this.dataColumns[i].prop,
            label: this.dataColumns[i].label === undefined || this.dataColumns[i].label === null ? this.dataColumns[i].prop.charAt(0).toUpperCase() + this.dataColumns[i].prop.slice(1) : this.dataColumns[i].label,
            width: this.dataColumns[i].width === undefined ? null : this.dataColumns[i].width,
            minWidth: this.dataColumns[i].minWidth === undefined ? null : this.dataColumns[i].minWidth,
            sortable: this.dataColumns[i].sortable === undefined ? null : this.dataColumns[i].sortable,
            localSortable: this.dataColumns[i].localSortable === undefined ? null : this.dataColumns[i].localSortable,
            resizable: this.dataColumns[i].resizable === undefined ? null : this.dataColumns[i].resizable,
            isToggle: this.dataColumns[i].isToggle === undefined ? null : this.dataColumns[i].isToggle,
            formatThisDate: this.dataColumns[i].formatThisDate === undefined ? false : this.dataColumns[i].formatThisDate,
            isNumeric: this.dataColumns[i].isNumeric === undefined ? false : this.dataColumns[i].isNumeric,
            hasCustomSlot: this.hasSlot('content_' + this.dataColumns[i].prop),
            align: this.dataColumns[i].align === undefined ? 'left' : this.dataColumns[i].align,
            needCurrency: this.dataColumns[i].needCurrency === undefined ? false : this.dataColumns[i].needCurrency,
            fixed: this.dataColumns[i].fixed === undefined ? null : this.dataColumns[i].fixed,
            wrapInLink: this.dataColumns[i].wrapInLink === undefined ? null : this.dataColumns[i].wrapInLink,
          })
        }
      },
      getDataTable (params = []) {
        this.isLoading = true
        this.failedToGetData = false
        this.$http.get(this.dataUrl + (this.dataUrl.indexOf('?') !== -1 ? '&' + this.setData().params(false, params).substr(1) : this.setData().params(false, params))).then(response => {
          this.tableData = response.data.data
          this.pagination.total = response.data.meta.total
          this.pagination.from = response.data.meta.from
          this.pagination.to = response.data.meta.to
          this.isLoading = false
          let toggleDatas = []
          this.tableColumns.forEach((tableColumn) => {
            if (tableColumn.isToggle) {
              toggleDatas.push(tableColumn.prop)
            }
          })
          this.$emit('loaded', response.data)
          // this.pagination.currentPage = response.data.page
        }).catch(error => {
          console.log(error)
          this.isLoading = false
          this.failedToGetData = true
          // this.$SmoothScroll(this.$el.querySelector('#dataTable'))
        })
      },
      setPage (page) {
        this.pagination.currentPage = page
      },
      onChangePage () {
        this.getDataTable()
      },
      doSorting (column, localSortable = false) {
        this.onSortChange({
          prop: column,
          order: this.pagination.order !== column ? 'ascending' : (!this.pagination.ascending ? 'ascending' : 'descending')
        })
        if (localSortable) {
          this.tableData = this.tableData.sort((a, b) => {
            if (this.pagination.ascending === 0) {
              return parseFloat(b[column]) - parseFloat(a[column])
            } else {
              return parseFloat(a[column]) - parseFloat(b[column])
            }
          });
        } else {
          // this.$refs.paginatedTable.sort(column, this.pagination.order !== column ? 'ascending' : (!this.pagination.ascending ? 'ascending' : 'descending'))
          this.getDataTable()
        }
      },
      doFilter (params) {
        this.setPage(1)
        this.setData().params(true, params)
        this.getDataTable()
      },
      onSortChange (column) {
        if (column.prop !== null && column.order) {
          this.pagination.order = column.prop
          this.pagination.ascending = column.order === 'ascending' ? 1 : 0
          this.setData().params(true)
        }
      },
      doDeleteData (id) {
        if (this.deleteReason) {
          this.$swal.fire({
            input: 'textarea',
            inputPlaceholder: 'Alasan Dihapus',
            title: 'Anda Yakin?',
            text: 'Data tidak akan dapat dikembalikan lagi!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak',
            // confirmButtonClass: 'btn btn-danger mr-3',
            // cancelButtonClass: 'btn btn-default',
            // buttonsStyling: false
          }).then(result => {
            if (typeof result.value !== 'undefined') {
              if (result.value !== '') {
                this.$http.post((this.deleteUrl !== null ? this.deleteUrl : this.dataUrl) + '/' + id, {
                  reason: result.value,
                  _method: 'DELETE'
                }).then(response => {
                  this.$store.dispatch('notification/success', 'Berhasil menghapus.')
                  this.getDataTable()
                  this.$emit('afterDeleteData', id)
                }).catch(error => {
                  this.$store.dispatch('notification/error', 'Gagal menghapus data.')
                  console.log(error)
                })
              } else {
                this.$store.dispatch('notification/error', 'Alasan wajib diisi.')
              }
            }
          })
        } else {
          this.$swal.fire({
            title: 'Anda Yakin?',
            text: 'Data tidak akan dapat dikembalikan lagi!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak',
            // confirmButtonClass: 'btn btn-danger mr-3',
            // cancelButtonClass: 'btn btn-default',
            // buttonsStyling: false
          }).then(result => {
            if (result.value) {
              this.$http.post((this.deleteUrl !== null ? this.deleteUrl : this.dataUrl) + '/' + id, {
                _method: 'DELETE'
              }).then(response => {
                this.$store.dispatch('notification/success', 'Berhasil menghapus.')
                this.getDataTable()
                this.$emit('afterDeleteData', id)
              }).catch(error => {
                this.$store.dispatch('notification/error', 'Gagal menghapus data.')
                console.log(error)
              })
            }
          })
        }
      },
      hasSlot (name = 'default') {
        return !!this.$slots[name] || !!this.$scopedSlots[name]
      },
      formatDate (date) {
        return this.$moment(date).format(process.env.VUE_APP_DATE_FORMAT_SHORT)
      },
      getLastPathUrl () {
        let tmp = this.$route.matched[this.$route.matched.length - 2].path
        if (tmp === '' || tmp.slice(-1) !== '/') tmp += '/'
        // return '#' + tmp
        return tmp
      },
      changeSwitch (prop, label, id, value) {
        this.$swal.fire({
          title: 'Anda yakin?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak',
          // confirmButtonClass: 'btn btn-warning mr-3',
          // cancelButtonClass: 'btn btn-default',
          // buttonsStyling: false
        }).then(result => {
          if (result.value) {
            let data = {}
            data[prop] = value
            this.$http.patch(this.dataUrl + '/' + id, data)
            .then(response => {
              this.$store.dispatch('notification/success', label + ' Berhasil diperbaharui.')
            })
            .catch(error => {
              const filteredTableData = this.tableData.filter((data) => {
                return data.id === id
              })
              this.$store.dispatch('notification/error', error)
              filteredTableData[0][prop] = value ? 0 : 1
            })
          } else {
            const filteredTableData = this.tableData.filter((data) => {
              return data.id === id
            })
            filteredTableData[0][prop] = value ? 0 : 1
          }
        })
      },
      applyDeleteCriteria (row) {
        if (this.deleteCriteria !== null) {
          if (this.deleteCriteria.criteria === '===') {
            return row[this.deleteCriteria.column] === this.deleteCriteria.value
          } else if (this.deleteCriteria.criteria === '<') {
            return row[this.deleteCriteria.column] < this.deleteCriteria.value
          } else if (this.deleteCriteria.criteria === '>') {
            return row[this.deleteCriteria.column] > this.deleteCriteria.value
          } else if (this.deleteCriteria.criteria === '>=') {
            return row[this.deleteCriteria.column] >= this.deleteCriteria.value
          } else if (this.deleteCriteria.criteria === '<=') {
            return row[this.deleteCriteria.column] <= this.deleteCriteria.value
          }
        }
      }
    },
    mounted () {
      this.initData().params().then(() => {
        this.initTableColumns()
        this.getDataTable()
      })
    }
  }
</script>
