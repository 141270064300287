import axios from 'axios'
import BaseModel from '@/models/BaseModel.js'

export default class PendukungRegionStat extends BaseModel {
    endpoint = process.env.VUE_APP_API_URL + 'pendukung/stats'

    list (params = {}) {
        return new Promise((resolve, reject) => {
            // const url = new URL(this.endpoint)
            // const urlParams = new URLSearchParams(url.search)
            // Object.keys(params).forEach(key => {
            //     if (typeof params[key] === 'string' || typeof params[key] === 'number') {
            //     urlParams.append(key, params[key])
            //     }
            // })
            // axios.get(this.endpoint, {
            //     params
            // }).then(response => {
            //     resolve(response.data)
            // }).catch(error => {
            //     reject(error)
            // })
            axios.post(this.endpoint, {
                ... params,
                _method: 'GET'
            }).then(response => {
              resolve(response.data)
            }).catch(error => {
              reject(error)
            })
        })
    }
}