<template>
  <div>
    <b-card no-header>
      <b-row class="mb-4">
        <b-col cols="12">
          <div class="d-flex mb-3">
            <b-button-group class="add-button-group ml-auto">
              <b-btn variant="success" type="button" @click.prevent="doExport"
                ><font-awesome-icon icon="file-excel" /> Ekspor</b-btn
              >
            </b-button-group>
          </div>
        </b-col>
        <b-col cols="12" class="mb-1 mt-4">
          <b-form @submit.prevent="doFilter">
            <b-row class="filter-row">
              <b-col cols="6" md="3">
                <form-group-input
                  ref="filter-nama"
                  type="text"
                  placeholder=""
                  :need-label="true"
                  label="Nama"
                  v-model="filter.nama"
                  id="filter-nama"
                />
              </b-col>
              <b-col cols="6" md="2">
                <label class="form-label">&nbsp;</label><br />
                <b-btn type="submit" class="btn-in-form" variant="primary">
                  <font-awesome-icon icon="search" /><span>Cari</span>
                </b-btn>
                <b-btn
                  type="button"
                  @click.prevent="clearForm"
                  variant="secondary"
                  class="btn-in-form ml-2"
                >
                  <font-awesome-icon icon="broom" /><span>Clear</span>
                </b-btn>
              </b-col>
            </b-row>
          </b-form>
        </b-col>
      </b-row>

      <paginated-table
        ref="dataTable"
        :delete-reason="false"
        :no-actions="true"
        :can-delete="false"
        :can-show="false"
        :can-edit="false"
        :data-url="apiUrl.koordinatorPendukung"
        :data-columns="columns"
        :dataParams="dataParams"
        :defaultDataParams="this.filter"
        :dataLimit="50"
        :noPushParam="true"
      >
        <template
          slot="content_caleg_pendukung_count"
          slot-scope="defaultSlotScope"
        >
          <p>{{ defaultSlotScope.colData.caleg.pendukung_count }}</p>
        </template>
      </paginated-table>
    </b-card>
  </div>
</template>

<script>
import { objectToOptions } from "@/_helpers";
import formGroupInput from "@/components/FormGroupInput.vue";
import paginatedTable from "@/components/PaginatedTable.vue";
import Party from "@/models/Party.js";
import City from "@/models/City.js";
import District from "@/models/District.js";
import KoordinatorPendukung from "@/models/KoordinatorPendukung.js";
// import User from '@/models/User.js'
// import CalegCountSummary from "@/models/CalegCountSummary.js";
import axios from "axios";

const partyModel = new Party();
const cityModel = new City();
const districtModel = new District();
const koordinatorPendukungModel = new KoordinatorPendukung();
// const calegCountSummaryModel = new CalegCountSummary()
// const userModel = new User()

export default {
  components: {
    paginatedTable,
    formGroupInput
  },
  data() {
    return {
      isLoading: false,
      caleg: {
        name: null,
        number: null,
        dapil_name: 0,
        vote_count: null
      },
      parties: [],
      party: null,
      city: null,
      apiUrl: {
        koordinatorPendukung: koordinatorPendukungModel.endpoint
        // district: districtModel.endpoint,
        // caleg: process.env.VUE_APP_API_URL + 'fake-data/caleg'
      },
      list: {
        city: [],
        district: [],
        village: [],
        pendukung: []
      },
      columns: [
        {
          prop: "nama",
          label: "Nama",
          sortable: true,
          resizable: true,
          minWidth: 200
        },
        {
          prop: "pendukung_count",
          label: "Jumlah Pendukung",
          sortable: true,
          resizable: true,
          minWidth: 200
        }
      ],
      filter: {
        with_total_pendukung_caleg: 1,
        caleg_id: this.$store.getters["account/accountData"].id,
        nama: ""
        // 'for_caleg': this.$route.params.calegId,
      },
      dataParams: ["with_total_pendukung_caleg", "caleg_id", "nama"]
    };
  },
  mounted() {
    // this.getKoordinator();
    // this.getPartyDetail();
    // this.getTotalVote();
    // this.getCalegDetail()
    // this.getCityDetail()
    // this.getCities();
  },
  watch: {
    // "$route.params.partyid": function (val) {
    //   if (val !== undefined) {
    //     this.getPartyDetail();
    //   }
    // },
    // "$route.params.cityId": function (val) {
    //   if (val !== undefined) {
    //     this.getCityDetail();
    //   }
    // },
  },
  methods: {
    doFilter() {
      this.$refs.dataTable.doFilter(this.filter);
    },
    // async getKoordinator() {
    //   let res = await axios.get(
    //     this.apiUrl.koordinatorPendukung + "?with_total_pendukung_caleg=1"
    //   );

    //   console.log(res.data);
    // },
    getCities() {
      cityModel
        .list({
          // 'name': 'labuhan',
          dapil_id: this.$store.getters["account/accountData"].dapil.id,
          limit: 10000
        })
        .then(resp => {
          let data = [];
          // data[null] = 'Tidak Ada'
          resp.data.forEach(d => {
            data[d.id] = d.name;
          });
          this.list.city = objectToOptions(data);
        });
    },
    async getTotalVote() {
      const resp = await axios.get(
        process.env.VUE_APP_API_URL + "me/total-vote"
      );
      this.caleg.vote_count = resp.data;
    },
    // getCalegDetail () {
    //   userModel.find(this.$store.getters['account/accountData'].id, {
    //     work_area_only: 1
    //   }).then(resp => {
    //     this.filter.dapil_id = resp.user_work_area.dapil.id
    //     this.caleg.name = resp.name
    //     this.caleg.number = resp.user_work_area.number
    //     this.caleg.dapil_name = resp.user_work_area.dapil.name
    //     this.caleg.vote_count = resp.user_work_area.vote_count
    //   })
    // },
    getPartyDetail() {
      partyModel
        .find(this.$store.getters["account/accountData"].party.id)
        .then(resp => {
          this.party = resp;
        });
    },
    getCityDetail() {
      cityModel.find(this.$route.params.cityId).then(resp => {
        this.city = resp;
      });
    },
    doExport() {
      if (this.isLoading) {
        return false;
      }
      // this.isLoading = true;
      const filterArray = [];
      // Object.keys(this.filter).forEach((key) => {
      //   if (this.filter[key]) {
      //     filterArray.push(key + "=" + this.filter[key]);
      //   }
      // });
      filterArray.push("xlsx=1");
      filterArray.push("caleg_id=" + this.$store.getters["account/accountData"].id);
      filterArray.push(
        "access_token=" + this.$store.getters["account/tokens"].access_token
      );

      window
        .open(
          koordinatorPendukungModel.getEndpoint() + "?" + filterArray.join("&"),
          "_blank"
        )
        .focus();
    }
  }
};
</script>
