<template>
  <div>
    <back-button default-to="/pendukung"/>
    <b-card no-header no-footer>
      <ValidationObserver ref="form">
        <form @submit.prevent="onSubmit">
            <b-row>
              <b-col cols="12" md="6">
                <div id="wrapper-input-nik" class="mb-3">
                  <div>
                    <label for="nik" class="form-label">Koordinator</label>
                    <v-select
                      v-if="mode !== 'update'"
                      class="w-100"
                      placeholder="Cari Koordinator" v-model="pendukungModel.koordinator_pendukung_id.value" :reduce="option => option.value" :options="list.koordinator" :filter="filterKoordinator">
                    </v-select>
                    <input v-if="mode === 'update'" type="text" readonly class="form-control" v-model="pendukungModel.koordinator_pendukung_id.value" >
                    
                    <input v-if="pendukungModel.koordinator_pendukung_id.value === 'Tambah Baru'" type="text" class="form-control mt-2" placeholder="Tambahkan Nama Koordinator" v-model="pendukungModel.koordinator_pendukung_name.value">
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="6">
                <!-- <h5>Informasi Pribadi</h5> -->
                <form-group-input type="hidden" v-model="pendukungModel.caleg_id.value" label="id caleg"/>

                <form-group-input v-if="mode === 'update' && $store.getters['account/accountData'].role !== 'caleg'" type="text" label="Caleg" :need-label="true" id="caleg_read_only" readonly placeholder="" />
                <template v-for="(m, index) in filterModel(
                    ['nkk', 'nik', 'nik', 'city_id', 'district_id', 'village_id', 'name', 'address']
                  )">
                  <div :key="'informasi_pribadi_' + index">
                    <div v-if="index === 'district_id'">
                      <form-group-input v-show="pendukungModel.city_id.value !== null" :ref="index" :type="m.type" :label="typeof m.label === 'string' ? m.label : null" :options="m.type === 'radio' ? getClassicOptions(index) : getOptions(index)" :need-label="true" v-model="m.value" :rules="typeof m.rules !== 'undefined' ? m.rules : null" :disabled="typeof m.disabled !== 'undefined' ? m.disabled : null" :id="index" />
                    </div>
                    <div v-else-if="index === 'village_id'">
                      <form-group-input v-show="pendukungModel.district_id.value !== null" :ref="index" :type="m.type" :label="typeof m.label === 'string' ? m.label : null" :options="m.type === 'radio' ? getClassicOptions(index) : getOptions(index)" :need-label="true" v-model="m.value" :rules="typeof m.rules !== 'undefined' ? m.rules : null" :disabled="typeof m.disabled !== 'undefined' ? m.disabled : null" :id="index" />
                    </div>
                    <form-group-input v-else-if="index === 'nik'" :ref="index" :type="m.type" :label="typeof m.label === 'string' ? m.label : null" :options="m.type === 'radio' ? getClassicOptions(index) : getOptions(index)" :need-label="true" v-model="m.value" :rules="typeof m.rules !== 'undefined' ? m.rules : null" :disabled="typeof m.disabled !== 'undefined' ? m.disabled : null" :id="index" @blur="checkNIK" />
                    <form-group-input v-else :ref="index" :type="m.type" :label="typeof m.label === 'string' ? m.label : null" :options="m.type === 'radio' ? getClassicOptions(index) : getOptions(index)" :need-label="true" v-model="m.value" :rules="typeof m.rules !== 'undefined' ? m.rules : null" :disabled="typeof m.disabled !== 'undefined' ? m.disabled : null" :id="index" />
                    <small v-if="mode === 'update' && index === 'password'" style="margin-top: -1rem !important; display: block;" class="mb-3">Kosongkan jika tidak ingin mengubah password.</small>
                  </div>
                  
                </template>
              </b-col>
              <b-col cols="12" md="6">
                <template v-for="(m, index) in filterModel(['gender', 'marriage_status', 'hometown', 'dob', 'age', 'phone_number'])">
                  <div :key="'informasi_pribadi_2' + index">
                    <form-group-input :ref="index" :type="m.type" :label="typeof m.label === 'string' ? m.label : null" :options="m.type === 'radio' ? getClassicOptions(index) : getOptions(index)" :need-label="true" v-model="m.value" :rules="typeof m.rules !== 'undefined' ? m.rules : null" :disabled="typeof m.disabled !== 'undefined' ? m.disabled : null" :id="index" />
                    <small v-if="mode === 'update' && index === 'password'" style="margin-top: -1rem !important; display: block;" class="mb-3">Kosongkan jika tidak ingin mengubah password.</small>
                  </div>
                </template>
              </b-col>
            </b-row>
            <div>
              <b-button type="submit" variant="primary" class="mt-0"><font-awesome-icon icon="spinner" spin v-if="isLoading"></font-awesome-icon> Simpan</b-button>
              <b-button :to="'/pendukung?caleg_id=' + $route.query.caleg_id + '&dapil_id=' + $route.query.dapil_id + '&parliament_region_id=' + $route.query.parliament_region_id" variant="secondary" class="ml-3 float-right"><font-awesome-icon icon="spinner" spin v-if="isLoading"></font-awesome-icon> Batal</b-button>
            </div>
          </form>
      </ValidationObserver>
    </b-card>
  </div>
</template>

<script>
import { saveProcess, setModelValue, scrollToError, objectToOptions, classicObjectToOptions } from '@/_helpers'
import formGroupInput from '@/components/FormGroupInput.vue'
import Pendukung from '@/models/Pendukung.js'
import City from '@/models/City.js'
import District from '@/models/District.js'
import Village from '@/models/Village.js'
import User from '@/models/User.js'
import KoordinatorPendukung from '@/models/KoordinatorPendukung.js'

const koordinatorPendukungModel = new KoordinatorPendukung()
const pendukungModel = new Pendukung()
const cityModel = new City()
const districtModel = new District()
const villageModel = new Village()
const calegModel = new User()

export default {
components: {
  formGroupInput
},
watch: {
  '$route.params.id': function (val) {
    if (this.mode === 'update') {
      this.getDetail()
    }
  },
  'pendukungModel.city_id.value': async function (val, oldVal) {
    if (val) {
      this.pendukungModel.district_id.options = await this.getDistricts(val)
      this.totalPromiseAreaCount++;
      if (val !== oldVal && !this.initEdit) {
        this.pendukungModel.district_id.value = null
      }
    } else {
      this.pendukungModel.district_id.value = null
    }
  },
  'pendukungModel.district_id.value': async function (val, oldVal) {
    if (val) {
      this.pendukungModel.village_id.options = await this.getVillages(val)
      this.totalPromiseAreaCount++;
      if (val !== oldVal && !this.initEdit) {
        this.pendukungModel.village_id.value = null
      }
    } else {
      this.pendukungModel.village_id.value = null
    }
  },
  'totalPromiseAreaCount': async function (val) {
    if (val >= 4) {
      this.initEdit = false;
    }
  },
},
computed: {
  mode: function () {
    return this.$route.params.id !== undefined ? 'update' : 'create'
  }
},
data () {
  return {
    totalPromiseAreaCount: 0,
    initEdit: false,
    nikAsli: null,
    isLoading: false,
    pendukungModel: pendukungModel.init(),
    state: {
      koordinator: false,   
    },
    list: {
      koordinator: [],
    }
  }
},
mounted () {
  if (this.mode === 'update') {
    this.getDetail()
  } else {
    this.doSearchKordinator()
    if (this.$store.getters['account/accountData'].role !== 'caleg') {
      this.pendukungModel.caleg_id.value = this.$route.query.caleg_id
    } else {
      this.pendukungModel.caleg_id.value = this.$store.getters['account/accountData'].id
    }
  }
  this.getCities()
  if (this.$store.getters['account/accountData'].role !== 'caleg') {
    // this.getCaleg()
  }
},
methods: {
  async checkNIK () {
    if (this.$store.getters['account/accountData'].role !== 'caleg' && !this.pendukungModel.caleg_id.value) {
      this.$swal.fire({
        title: 'Harap Mengisi Data Caleg Lalu Isi Kembali Nik',
        text: '',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'Tutup',
      }).then(() => {
        document.querySelector("input#nik.form-control").value = ''
      })
    } else if (document.querySelector("input#nik.form-control").value && this.nikAsli != document.querySelector("input#nik.form-control").value) {
      const checkNIK = await pendukungModel.checkNIK(document.querySelector("input#nik.form-control").value, this.$store.getters['account/accountData'].role !== 'caleg' ? this.pendukungModel.caleg_id.value : this.$store.getters['account/accountData'].id, this.$route.query.parliament_region_id)
      console.log('checkNIK', document.querySelector("input#nik.form-control").value)
      if (checkNIK) {
        if (checkNIK.status_dpt == 1) {
          this.$swal.fire({
            title: 'NIK termasuk DPT',
            text: 'Gunakan fitur tambah Pendukung DPT',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonText: 'Tutup',
          }).then(() => {
            setTimeout(() => {
              document.querySelector("input#nik.form-control").focus()
            }, 500)
          })
        } else if (checkNIK.status_pendukung == 1) {
          this.$swal.fire({
            title: 'NIK termasuk Pendukung',
            text: 'NIK sudah terdaftar sebagai pendukung',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonText: 'Tutup',
          }).then(() => {
            setTimeout(() => {
              document.querySelector("input#nik.form-control").focus()
            }, 500)
          })
        }
      }
    }
  },
  filterModel (search) {
    const filtered = Object.entries(this.pendukungModel).filter(mdl => {
      return search.includes(mdl[0])
    });
    let result = {}
    filtered.forEach(element => {
      result[element[0]] = element[1];
    });
    return result;
  },
  getCaleg () {
    calegModel.list({
      only_has_access: 1,
      with_user_work_area: 1,
      limit: 1000,
      roles: 'caleg'
    }).then(resp => {
      let data = []
      // data[null] = 'Tidak Ada'
      resp.data.forEach(d => {
        data[d.id] = d.name + ', Partai: ' + d.user_work_area.party.name
      })
      this.pendukungModel.caleg_id.options = data
    })
  },
  getCities () {
    cityModel.list({
      'registered_only': 1,
      // 'name': 'labuhan',
      'dapil_id': typeof this.$route.query.dapil_id !== 'undefined' ? this.$route.query.dapil_id : '',
      'province_id': '950b9d3a-c996-4207-97e7-5a2858981979',
      'limit': 10000
    }).then(resp => {
      let data = []
      // data[null] = 'Tidak Ada'
      resp.data.forEach(d => {
        data[d.id] = d.name
      })
      this.pendukungModel.city_id.options = data
      if (resp.data.length === 1) {
        this.pendukungModel.city_id.value = resp.data[0].id
      } 
    })
  },
  async getDistricts (cityId) {
    return new Promise(resolve => {
      districtModel.list({
        'city_id': cityId
      }).then(resp => {
        let data = []
        // data[null] = 'Tidak Ada'
        resp.data.forEach(d => {
          data[d.id] = d.name
        })
        resolve(data);
        // this.pendukungModel.district_id.options = data
      })
    })
  },
  async getVillages (districtId) {
    return new Promise(resolve => {
      villageModel.list({
        'district_id': districtId,
      }).then(resp => {
        let data = []
        // data[null] = 'Tidak Ada'
        resp.data.forEach(d => {
          data[d.id] = d.name
        })
        resolve(data);
        // this.pendukungModel.village_id.options = data
      })
    })
  },
  getOptions (key) {
    return typeof this.pendukungModel[key].options !== 'undefined' ? objectToOptions(this.pendukungModel[key].options) : null
  },
  getClassicOptions (key) {
    return typeof this.pendukungModel[key].options !== 'undefined' ? classicObjectToOptions(this.pendukungModel[key].options) : null
  },
  getDetail () {
    this.initEdit = true
    this.isLoading = true
    pendukungModel.find(this.$route.params.id).then(resp => {
      this.isLoading = false;
      this.nikAsli = resp.nik
      this.pendukungModel.caleg_id.disabled = true;
      delete resp.caleg_id;

      setModelValue(this.pendukungModel, resp)
      Object.keys(this.pendukungModel).forEach(key => {
        if (!['caleg_id'].includes(key) && typeof this.$refs[key] !== 'undefined') {
          this.$refs[key][0].setValue(this.pendukungModel[key].value)
        }
      })

      this.pendukungModel.koordinator_pendukung_id.value = '-'
      if (resp.koordinator_pendukung_id) {
        koordinatorPendukungModel.find(resp.koordinator_pendukung_id).then(resp => {
          this.pendukungModel.koordinator_pendukung_id.value = resp.nama
        })
      }
    }).catch(error => {
      if (process.env.NODE_ENV !== 'production') {
        console.error(error)
      }
      // this.initEdit = false
      this.isLoading = false
    })
    if (this.$store.getters['account/accountData'].role !== 'caleg') {
      pendukungModel.getCalegDetail(this.$route.params.id).then(resp => {
        setTimeout(() => {
          document.getElementById('caleg_read_only').value = resp.map(caleg => {return caleg.name}).join(', ')
        }, 500)
      })
    }
  },
  resetForm () {
    this.$nextTick(() => {
      // clearModelValue(this.pendukungModel)
      Object.keys(this.pendukungModel).forEach(key => {
        this.$refs[key][0].setValue(null)
      })
      this.$refs.form.reset()
    })
  },
  onSubmit () {
    this.$refs.form.validate().then(success => {
      if (!success || this.isLoading) {
        scrollToError(this.$refs.form)
        return false
      }
      saveProcess(this.pendukungModel, this.mode === 'update').then(model => {
        this.isLoading = true
        if (this.mode === 'update') {
          pendukungModel.update(this.$route.params.id, model).then(() => {
            this.isLoading = false
            this.$store.dispatch('notification/success', 'Data pendukung berhasil diubah.')
            this.$router.push({
              path: '/pendukung',
              query: {
                caleg_id: this.$route.query.caleg_id,
                dapil_id: this.$route.query.dapil_id,
                parliament_region_id: this.$route.query.parliament_region_id,
              }
            })
            // this.$router.push('/pendukung/' + this.$route.params.id)
          }).catch(error => {
            this.isLoading = false
            this.$store.dispatch('notification/error', error)
          })
        } else {
          pendukungModel.create(model).then(resp => {
            this.isLoading = false
            // this.resetForm()
            this.$store.dispatch('notification/success', 'Data pendukung berhasil disimpan.')
            // this.$router.push('/pendukung')
            this.$router.push({
              path: '/pendukung',
              query: {
                caleg_id: this.$route.query.caleg_id,
                dapil_id: this.$route.query.dapil_id,
                parliament_region_id: this.$route.query.parliament_region_id,
              }
            })
            // this.$router.push('/pendukung/' + resp.data.id)
          }).catch(error => {
            this.isLoading = false
            this.$store.dispatch('notification/error', error)
          })
        }
      }).catch(() => {})
    })
  },
  async doSearchKordinator () {
    if (!this.state.koordinator) {
      const koordinatorList = await koordinatorPendukungModel.list({
        // nama: search,
        caleg_id: this.$store.getters['account/accountData'].role !== 'caleg' ? this.$route.query.caleg_id : this.$store.getters['account/accountData'].id
      });
      const options = []
      koordinatorList.data.forEach(user => {
        options.push({
          value: user.id,
          label: user.nama
        });
      });
      this.list.koordinator = options
      this.state.koordinator = true
    }
  },
  filterKoordinator (options, search) {
    if (search) {
        options =  options.filter(option => {
            return option.label.toLowerCase().includes(search.toLowerCase())
        })
        if (!options.length) {
            options.push({
                value: 'Tambah Baru',
                label: 'Tambah Baru'
            })
        }
    }
    return options
  }
}
}
</script>