export default class WebLocalStorage {
    init () {
      return new Promise((resolve) => {
        resolve('success');
      })
    }
    async destroyCalegStorage () {
        // await this.dataCalegCities().reset()
        Object.keys(localStorage).forEach(localStorageKey => {
            if (localStorageKey.includes('caleg_')) {
                localStorage.removeItem(localStorageKey);
            }
        });

        return Promise.resolve('success')
    }
    dataCalegCities () {
        const storageName = 'caleg_cities';
        return {
            get: () => {
                return new Promise((resolve) => {
                    const item = localStorage.getItem(storageName);
                    if (item) {
                        return resolve(JSON.parse(item));
                    }
                    return resolve(null);
                })
            },
            save: async (data) => {
                localStorage.setItem(storageName, JSON.stringify(data));
                return Promise.resolve(data)
            },
            reset: async () => {
                localStorage.removeItem(storageName);
                return Promise.resolve('success')
            }
        }
    }
    dataPerolehanCaleg (suffix = null) {
        let storageName = 'caleg_perolehan';
        if (suffix) {
            storageName += '_' + suffix
        }
        return {
            get: () => {
                return new Promise((resolve) => {
                    const item = localStorage.getItem(storageName);
                    if (item) {
                        return resolve(JSON.parse(item));
                    }
                    return resolve(null);
                })
            },
            save: async (data) => {
                localStorage.setItem(storageName, JSON.stringify(data));
                return Promise.resolve(data)
            },
            reset: async () => {
                localStorage.removeItem(storageName);
                return Promise.resolve('success')
            }
        }
    }
    dataPendukungCaleg (suffix = null) {
        let storageName = 'caleg_pendukung';
        if (suffix) {
            storageName += '_' + suffix
        }
        return {
            get: () => {
                return new Promise((resolve) => {
                    const item = localStorage.getItem(storageName);
                    if (item) {
                        return resolve(JSON.parse(item));
                    }
                    return resolve(null);
                })
            },
            save: async (data) => {
                localStorage.setItem(storageName, JSON.stringify(data));
                return Promise.resolve(data)
            },
            reset: async () => {
                localStorage.removeItem(storageName);
                return Promise.resolve('success')
            }
        }
    }
}